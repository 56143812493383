import { ACTIONS } from '@/models/store';
import { Footer, Menu } from '@/models/wordpress';
import { store } from '@/store';
import QUERY from '@/utils/query';
import axios from 'axios';

class WordpressService {
  static async footer(id: string): Promise<Footer> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.FOOTER(id));

    const footer = response.values[0];

    if (footer) {
      footer.footer_members = JSON.parse(footer.footer_members);
    }

    return footer;
  }

  static async menu(): Promise<Menu[]> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.MENU);

    const footer = response.values;

    return footer;
  }

  static async menuData(): Promise<Menu[] | null> {
    const IS_ONLINE = window.navigator.onLine;
    let response: Menu[] | null;

    if (IS_ONLINE) {
      try {
        const httpResponse = await axios.get(`${process.env.VUE_APP_BASE_APP_URL}${process.env.VUE_APP_WP_MENU_API_URL}`);

        const { data: { items } } = httpResponse;

        response = (items as any[]).map((item) => {
          return {
            id: item.ID,
            title: item.title,
            url: item.url,
            menu_order: item.menu_order
          }
        });
      } catch (error) {
        response = null;
      }
    } else {
      response = null;
    }

    return response;
  }

  static async footerData(): Promise<Footer | null> {
    const IS_ONLINE = window.navigator.onLine;
    let response: Footer | null;

    if (IS_ONLINE) {
      try {
        const httpResponse = await axios.get(`${process.env.VUE_APP_BASE_APP_URL}${process.env.VUE_APP_WP_FOOTER_API_URL}`);

        const { data: { acf } } = httpResponse

        response = {
          city: acf.contacts_city,
          email: acf.contacts_email,
          phone: acf.contacts_phone,
          street: acf.contacts_street,
          websitetitle: acf.contacts_websitetitle,
          websiteurl: acf.contacts_websiteurl,
          zipcode: acf.contacts_zipcode,
          googlemapsurl: acf.contacts_googlemapsurl,
          footer_members: acf.footer_members
        };
      } catch (error) {
        response = null;
      }
    } else {
      response = null;
    }

    return response;
  }
}

export default WordpressService;
