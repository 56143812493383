import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toggle = _resolveComponent("ion-toggle")!

  return (_openBlock(), _createBlock(_component_ion_toggle, {
    color: "primary",
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    mode: "ios"
  }, null, 8, ["modelValue"]))
}