/* eslint-disable */
import { SearchQuery } from '@/models/backstep';
import { EntityCategory, ProducerContact } from '@/models/medicine';
import BackStepService from '@/services/BackStepService';
import CategoryService from '@/services/CategoryService';
import MedicineService from '@/services/MedicineService';
import SmpcService from '@/services/SmpcService';
import WordpressService from '@/services/WordpressService';
import { Utils } from '@/utils/utils';

const QUERY = {
  CACHE: {
    async CATEGORIES_TYPES(date?: string): Promise<string[]> {
      let query = [] as string[];

      const categoryTypes = await CategoryService.categoryTypesData({
        updated_since: Utils.formatDate(date),
      });

      if (categoryTypes?.data.length) {
        const ins_values: string[] = [];
        const upd_values: string[] = [];
        const del_values: string[] = [];

        await categoryTypes?.data.reduce(async (promise, categoryType) => {
          await promise;

          let DO_DELETE = false;

          if (!['', 'null', null].includes(categoryType.deleted_at)) {
            DO_DELETE = true;
          }

          if (DO_DELETE) {
            let value = `
              DELETE FROM _categoryType_ WHERE id='${categoryType.id}';
            `;

            del_values.push(value);
          } else {
            const categoryTypeCheck = await CategoryService.categoryTypeById(categoryType.id);

            const DO_INSERT = categoryTypeCheck === undefined;

            if (DO_INSERT) {
              let value = `INSERT INTO _categoryType_ (id,code,title,created_at,updated_at,deleted_at) VALUES (
                  '${categoryType.id}',
                  '${categoryType.code}',
                  '${Utils.convertToEscapeCharacter(categoryType.title)}',
                  '${categoryType.created_at}',
                  '${categoryType.updated_at}',
                  '${categoryType.deleted_at}'
              );`;

              ins_values.push(value);
            } else {
              let value = `
                UPDATE _categoryType_
                SET code='${categoryType.code}',
                title='${Utils.convertToEscapeCharacter(categoryType.title)}',
                created_at='${categoryType.created_at}',
                updated_at='${categoryType.updated_at}',
                deleted_at='${categoryType.deleted_at}'
                WHERE id='${categoryType.id}';
              `;

              upd_values.push(value);
            }
          }
        }, Promise.resolve());

        query.push(`
          CREATE TABLE IF NOT EXISTS _categoryType_ (
            id TEXT PRIMARY KEY NOT NULL,
            code TEXT,
            title TEXT,
            created_at DATE,
            updated_at DATE,
            deleted_at DATE
          );
        `);

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        upd_values.forEach((upd) => {
          query.push(upd);
        });

        del_values.forEach((del) => {
          query.push(del);
        });
      }

      return query;
    },

    async CATEGORIES(date?: string): Promise<string[]> {
      let query = [] as string[];

      const categories = await CategoryService.categoryData({
        updated_since: Utils.formatDate(date),
      });

      if (categories?.data.length) {
        const ins_values: string[] = [];
        const upd_values: string[] = [];
        const del_values: string[] = [];

        await categories?.data.reduce(async (promise, category) => {
          await promise;

          let DO_DELETE = false;

          if (!['', 'null', null].includes(category.deleted_at)) {
            DO_DELETE = true;
          }

          if (DO_DELETE) {
            let value = `
              DELETE FROM _categories_ WHERE id='${category.id}';
            `;

            del_values.push(value);
          } else {
            const categoryCheck = await CategoryService.categoryById(category.id);

            const DO_INSERT = categoryCheck === undefined;

            if (DO_INSERT) {
              let value = `INSERT INTO _categories_ (id,code,title,parent_category_id,category_type_id,item_count,orderby,created_at,updated_at,deleted_at) VALUES (
                  '${category.id}',
                  '${category.code}',
                  '${Utils.convertToEscapeCharacter(category.title)}',
                  '${category.parent_category_id}',
                  '${category.category_type_id}',
                  ${category.item_count ? category.item_count : 0},
                  ${category.details && !Array.isArray(category.details) ? 'order' in category.details ? category.details.order : 0 : 0},
                  '${category.created_at}',
                  '${category.updated_at}',
                  '${category.deleted_at}'
              );`;

              ins_values.push(value);
            } else {
              let value = `
                UPDATE _categories_
                SET code='${category.code}',
                title='${Utils.convertToEscapeCharacter(category.title)}',
                parent_category_id='${category.parent_category_id}',
                category_type_id='${category.category_type_id}',
                item_count=${category.item_count ? category.item_count : 0},
                orderby=${category.details && !Array.isArray(category.details) ? 'order' in category.details ? category.details.order : 0 : 0},
                created_at='${category.created_at}',
                updated_at='${category.updated_at}',
                deleted_at='${category.deleted_at}'
                WHERE id='${category.id}';
              `;

              upd_values.push(value);
            }
          }
        }, Promise.resolve());

        query.push(`
          CREATE TABLE IF NOT EXISTS _categories_ (
            id TEXT PRIMARY KEY NOT NULL,
            code TEXT,
            title TEXT,
            parent_category_id TEXT,
            category_type_id TEXT,
            item_count INTEGER,
            orderby INTEGER,
            created_at DATE,
            updated_at DATE,
            deleted_at DATE
          );
        `);

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        upd_values.forEach((upd) => {
          query.push(upd);
        });

        del_values.forEach((del) => {
          query.push(del);
        });
      }

      return query;
    },

    async MEDICINES(date?: string): Promise<string[]> {
      let query = [] as string[];

      const medicines = await MedicineService.medicineData({
        updated_since: Utils.formatDate(date),
      });

      if (medicines?.data.length) {
        const ins_values: string[] = [];
        const upd_values: string[] = [];
        const del_values: string[] = [];

        await medicines?.data.reduce(async (promise, medicine) => {
          await promise;

          let DO_DELETE = false;

          if (!['', 'null', null].includes(medicine.deleted_at)) {
            DO_DELETE = true;
          }

          if (DO_DELETE) {
            let value = `
              DELETE FROM _medicine_ WHERE id='${medicine.id}';
            `;

            del_values.push(value);
          } else {
            const medicineCheck = await MedicineService.medicineCheck(medicine.id);

            const DO_INSERT = medicineCheck === undefined;

            let smpc_id = null;

            if (medicine.details.smpc_details) {
              smpc_id = medicine.details.smpc_details.source;
            }

            const details = medicine.details;

            if (DO_INSERT) {
              let value = `INSERT INTO _medicine_ (id,producer_id,smpc_id,code,title,promoted,published,dosage_form,strength,withdrawal_period,images,files,details,updated_at) VALUES (
                  '${medicine.id}',
                  '${medicine.producer_id}',
                  '${smpc_id}',
                  '${medicine.code}',
                  '${Utils.convertToEscapeCharacter(medicine.title)}',
                  ${medicine.promoted},
                  ${medicine.published},
                  '${Utils.convertToEscapeCharacter(details.dosage_form.text)}',
                  '${Utils.convertToEscapeCharacter(details.strength.text)}',
                  '${Utils.convertToEscapeCharacter(details.withdrawal_period)}',
                  '${Utils.convertToEscapeCharacter(JSON.stringify(details.images))}',
                  '${Utils.convertToEscapeCharacter(JSON.stringify(details.files))}',
                  '${Utils.convertToEscapeCharacter(JSON.stringify(details))}',
                  '${medicine.updated_at}'
              );`;

              ins_values.push(value);
            } else {
              let value = `
                UPDATE _medicine_
                SET producer_id='${medicine.producer_id}',
                smpc_id='${smpc_id}',
                code='${medicine.code}',
                title='${Utils.convertToEscapeCharacter(medicine.title)}',
                promoted=${medicine.promoted},
                published=${medicine.published},
                dosage_form='${Utils.convertToEscapeCharacter(details.dosage_form.text)}',
                strength='${Utils.convertToEscapeCharacter(details.strength.text)}',
                withdrawal_period='${Utils.convertToEscapeCharacter(details.withdrawal_period)}',
                images='${Utils.convertToEscapeCharacter(JSON.stringify(details.images))}',
                files='${Utils.convertToEscapeCharacter(JSON.stringify(details.files))}',
                details='${Utils.convertToEscapeCharacter(JSON.stringify(details))}',
                updated_at='${medicine.updated_at}'
                WHERE id='${medicine.id}';
              `;

              upd_values.push(value);
            }
          }
        }, Promise.resolve());

        query.push(`
          CREATE TABLE IF NOT EXISTS _medicine_ (
            id TEXT PRIMARY KEY NOT NULL,
            producer_id TEXT,
            smpc_id TEXT,
            code TEXT,
            title TEXT,
            promoted BIT,
            published BIT,
            dosage_form TEXT,
            strength TEXT,
            withdrawal_period TEXT,
            images TEXT,
            files TEXT,
            details TEXT,
            updated_at DATE
          );
        `);

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        upd_values.forEach((upd) => {
          query.push(upd);
        });

        del_values.forEach((del) => {
          query.push(del);
        });
      }

      return query;
    },

    async PRODUCERS(date?: string): Promise<string[]> {
      let query = [] as string[];

      const producers = await MedicineService.producerData({
        updated_since: Utils.formatDate(date),
      });

      if (producers?.data.length) {
        const ins_values: string[] = [];
        const upd_values: string[] = [];
        const del_values: string[] = [];

        await producers?.data.reduce(async (promise, producer) => {
          await promise;

          let DO_DELETE = false;

          if (!['', 'null', null].includes(producer.deleted_at)) {
            DO_DELETE = true;
          }

          if (DO_DELETE) {
            let value = `
              DELETE FROM _producer_ WHERE id='${producer.id}';
            `;

            value = value
              .replace(/\n/g, '')

            del_values.push(value);
          } else {
            const producerCheck = await MedicineService.producer(producer.id);

            const DO_INSERT = producerCheck === undefined;

            if (!producer.details.contacts) {
              producer.details.contacts = [] as ProducerContact[];
            }

            if (DO_INSERT) {
              let value = `INSERT INTO _producer_ (id,code,title,description,details) VALUES (
                  '${producer.id}',
                  '${producer.code}',
                  '${Utils.convertToEscapeCharacter(producer.title)}',
                  '${Utils.convertToEscapeCharacter(producer.description)}',
                  '${Utils.convertToEscapeCharacter(JSON.stringify(producer.details))}'
              );`;

              value = value
                .replace(/\n/g, '')

              ins_values.push(value);
            } else {
              let value = `
                UPDATE _producer_
                SET code='${producer.code}',
                title='${Utils.convertToEscapeCharacter(producer.title)}',
                description='${Utils.convertToEscapeCharacter(producer.description)}',
                details='${Utils.convertToEscapeCharacter(JSON.stringify(producer.details))}'
                WHERE id='${producer.id}';
              `;

              value = value
                .replace(/\n/g, '')

              upd_values.push(value);
            }
          }
        }, Promise.resolve());

        query.push(`
          CREATE TABLE IF NOT EXISTS _producer_ (
            id TEXT PRIMARY KEY NOT NULL,
            code TEXT,
            title TEXT,
            description TEXT,
            details TEXT
          );
        `);

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        upd_values.forEach((upd) => {
          query.push(upd);
        });

        del_values.forEach((del) => {
          query.push(del);
        });
      }

      return query;
    },

    async PACKAGES(date?: string): Promise<string[]> {
      let query = [] as string[];

      const packages = await MedicineService.packageData({
        updated_since: Utils.formatDate(date),
      });

      if (packages?.data.length) {
        const ins_values: string[] = [];
        const upd_values: string[] = [];
        const del_values: string[] = [];

        await packages?.data.reduce(async (promise, pack) => {
          await promise;

          let DO_DELETE = false;

          if (!['', 'null', null].includes(pack.deleted_at)) {
            DO_DELETE = true;
          }

          if (DO_DELETE) {
            let value = `
              DELETE FROM _package_ WHERE id='${pack.id}';
            `;

            value = value
              .replace(/\n/g, '')

            del_values.push(value);
          } else {
            const packageCheck = await MedicineService.package(pack.id);

            const DO_INSERT = packageCheck == undefined;

            if (DO_INSERT) {
              let value = `INSERT INTO _package_ (id,item_id,code,title,details,published) VALUES (
                  '${pack.id}',
                  '${pack.item_id}',
                  '${pack.code}',
                  '${Utils.convertToEscapeCharacter(pack.title)}',
                  '${Utils.convertToEscapeCharacter(JSON.stringify(pack.details))}',
                  ${pack.published}
              );`;

              value = value
                .replace(/\n/g, '')

              ins_values.push(value);
            } else {
              let value = `
                UPDATE _package_
                SET item_id='${pack.item_id}',
                code='${pack.code}',
                title='${Utils.convertToEscapeCharacter(pack.title)}',
                details='${Utils.convertToEscapeCharacter(JSON.stringify(pack.details))}',
                published=${pack.published}
                WHERE id='${pack.id}';
              `;

              value = value
                .replace(/\n/g, '')

              upd_values.push(value);
            }
          }

        }, Promise.resolve());

        query.push(`
          CREATE TABLE IF NOT EXISTS _package_ (
            id TEXT PRIMARY KEY NOT NULL,
            item_id TEXT,
            code TEXT,
            title TEXT,
            details TEXT,
            published BIT
          );
        `);

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        upd_values.forEach((upd) => {
          query.push(upd);
        });

        del_values.forEach((del) => {
          query.push(del);
        });
      }

      return query;
    },

    async ENTITY_CATEGORIES(date?: string): Promise<string[]> {
      let query = [] as string[];

      const entityCategories = await MedicineService.entityCategoryData({
        updated_since: Utils.formatDate(date),
      });

      if (entityCategories?.data.length) {
        const ins_values: string[] = [];
        const new_values: string[] = [];
        const upd_cats = [] as EntityCategory[];
        let catGroupByEntityId = {} as any;

        entityCategories?.data.forEach((category) => {
          if (!date?.length) {
            let value = `INSERT INTO _entityCategories_ (id,entity_id,category_id,category_title,category_code,category_type_id,category_type_code,created_at,updated_at,deleted_at) VALUES (
              ${category.id},
              '${category.entity_id}',
              '${category.category_id}',
              '${Utils.convertToEscapeCharacter(category.category_title)}',
              '${category.category_code}',
              '${category.category_type_id}',
              '${category.category_type_code}',
              '${category.created_at}',
              '${category.updated_at}',
              '${category.deleted_at}'
            );`;

            value = value
              .replace(/\n/g, '')

            ins_values.push(value);
          } else {
            upd_cats.push(category);
          }
        });

        if (upd_cats.length) {
          catGroupByEntityId = upd_cats.reduce((r, a) => {
            r[a.entity_id] = [...r[a.entity_id] || [], a];
            return r;
          }, {} as any);

          upd_cats.forEach((category) => {
            let value = `INSERT INTO _entityCategories_ (id,entity_id,category_id,category_title,category_code,category_type_id,category_type_code,created_at,updated_at,deleted_at) VALUES (
              ${category.id},
              '${category.entity_id}',
              '${category.category_id}',
              '${Utils.convertToEscapeCharacter(category.category_title)}',
              '${category.category_code}',
              '${category.category_type_id}',
              '${category.category_type_code}',
              '${category.created_at}',
              '${category.updated_at}',
              '${category.deleted_at}'
            );`;

            value = value
              .replace(/\n/g, '')

            new_values.push(value);
          });
        }

        query.push(`
          CREATE TABLE IF NOT EXISTS _entityCategories_ (
            id INTEGER PRIMARY KEY NOT NULL,
            entity_id TEXT,
            category_id TEXT,
            category_title TEXT,
            category_code TEXT,
            category_type_id TEXT,
            category_type_code TEXT,
            created_at DATE,
            updated_at DATE,
            deleted_at DATE
          );
        `);

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        if (new_values.length) {
          query.push(`
            DELETE FROM _entityCategories_ WHERE entity_id IN (${Object.keys(catGroupByEntityId).map((id) => `'${id}'`).join(',')});
          `);

          new_values.forEach((upd) => {
            query.push(upd);
          });
        }
      }

      return query;
    },

    async UNIT_CATEGORIES(date?: string): Promise<string[]> {
      let query = [] as string[];

      const unitCategories = await MedicineService.unitCategoryData({
        updated_since: Utils.formatDate(date),
      });

      if (unitCategories?.data.length) {
        const ins_values: string[] = [];
        const new_values: string[] = [];
        const upd_cats = [] as EntityCategory[];
        let catGroupByEntityId = {} as any;

        unitCategories?.data.forEach((category) => {
          if (!date?.length) {
            let value = `INSERT INTO _unitCategories_ (id,entity_id,category_id,category_title,category_code,category_type_id,category_type_code,created_at,updated_at,deleted_at) VALUES (
              ${category.id},
              '${category.entity_id}',
              '${category.category_id}',
              '${Utils.convertToEscapeCharacter(category.category_title)}',
              '${category.category_code}',
              '${category.category_type_id}',
              '${category.category_type_code}',
              '${category.created_at}',
              '${category.updated_at}',
              '${category.deleted_at}'
            );`;

            value = value
              .replace(/\n/g, '')

            ins_values.push(value);
          } else {
            upd_cats.push(category);
          }
        });

        if (upd_cats.length) {
          catGroupByEntityId = upd_cats.reduce((r, a) => {
            r[a.entity_id] = [...r[a.entity_id] || [], a];
            return r;
          }, {} as any);

          upd_cats.forEach((category) => {
            let value = `INSERT INTO _unitCategories_ (id,entity_id,category_id,category_title,category_code,category_type_id,category_type_code,created_at,updated_at,deleted_at) VALUES (
              ${category.id},
              '${category.entity_id}',
              '${category.category_id}',
              '${Utils.convertToEscapeCharacter(category.category_title)}',
              '${category.category_code}',
              '${category.category_type_id}',
              '${category.category_type_code}',
              '${category.created_at}',
              '${category.updated_at}',
              '${category.deleted_at}'
            );`;

            value = value
              .replace(/\n/g, '')

            new_values.push(value);
          });
        }


        query.push(`
          CREATE TABLE IF NOT EXISTS _unitCategories_ (
            id INTEGER PRIMARY KEY NOT NULL,
            entity_id TEXT,
            category_id TEXT,
            category_title TEXT,
            category_code TEXT,
            category_type_id TEXT,
            category_type_code TEXT,
            created_at DATE,
            updated_at DATE,
            deleted_at DATE
          );
        `);

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        if (new_values.length) {
          query.push(`
            DELETE FROM _unitCategories_ WHERE entity_id IN (${Object.keys(catGroupByEntityId).map((id) => `'${id}'`).join(',')});
          `);

          new_values.forEach((upd) => {
            query.push(upd);
          });
        }
      }

      return query;
    },

    async SMPCS(date?: string): Promise<string[]> {
      let query = [] as string[];

      const smpcs = await SmpcService.smpcData({
        updated_since: Utils.formatDate(date),
      });

      if (smpcs?.data.length) {
        const ins_values: string[] = [];
        const upd_values: string[] = [];
        const del_values: string[] = [];

        await smpcs?.data.reduce(async (promise, smpc) => {
          await promise;

          let DO_DELETE = false;

          if (!['', 'null', null].includes(smpc.deleted_at)) {
            DO_DELETE = true;
          }

          if (DO_DELETE) {
            let value = `
              DELETE FROM _smpc_ WHERE id='${smpc.id}';
            `;

            value = value
              .replace(/\n/g, '')

            del_values.push(value);
          } else {
            const smpcCheck = await SmpcService.smpc(smpc.id);

            const DO_INSERT = smpcCheck === undefined;

            if (DO_INSERT) {
              let value = `INSERT INTO _smpc_ (id,name,smpc_sections,artifact) VALUES (
                '${smpc.id}',
                '${Utils.convertToEscapeCharacter(smpc.name)}',
                '${Utils.convertToEscapeCharacter(JSON.stringify(smpc.smpc_details.smpc_sections))}',
                '${Utils.convertToEscapeCharacter(JSON.stringify(smpc.artifact))}'
              );`;

              value = value
                .replace(/\n/g, '')

              ins_values.push(value);
            } else {
              let value = `
                UPDATE _smpc_
                SET name='${smpc.name}',
                smpc_sections='${Utils.convertToEscapeCharacter(JSON.stringify(smpc.smpc_details.smpc_sections))}',
                artifact='${Utils.convertToEscapeCharacter(JSON.stringify(smpc.artifact))}'
                WHERE id='${smpc.id}';
              `;

              value = value
                .replace(/\n/g, '')

              upd_values.push(value);
            }
          }
        }, Promise.resolve());

        query.push(`
          CREATE TABLE IF NOT EXISTS _smpc_ (
            id TEXT PRIMARY KEY NOT NULL,
            name TEXT,
            smpc_sections TEXT,
            artifact TEXT
          );
        `)

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        upd_values.forEach((upd) => {
          query.push(upd);
        });

        del_values.forEach((del) => {
          query.push(del);
        });
      }

      return query;
    },

    async WORDPRESS_FOOTER(): Promise<string[]> {
      let query = [] as string[];

      const id = process.env.VUE_APP_WORDPRESS_API_CACHE_KEY

      const footer = await WordpressService.footerData();

      const footer_cached = await WordpressService.footer(id);

      if (footer) {
        const ins_values: string[] = [];
        const upd_values: string[] = [];

        let DO_INSERT = true;

        if (footer_cached) {
          DO_INSERT = false;
        }

        if (DO_INSERT) {
          let value = `INSERT INTO _footer_ (id,city,email,footer_members,googlemapsurl,phone,street,websitetitle,websiteurl,zipcode) VALUES (
            '${id}',
            '${Utils.convertToEscapeCharacter(footer.city)}',
            '${Utils.convertToEscapeCharacter(footer.email)}',
            '${Utils.convertToEscapeCharacter(JSON.stringify(footer.footer_members))}',
            '${Utils.convertToEscapeCharacter(footer.googlemapsurl)}',
            '${Utils.convertToEscapeCharacter(footer.phone)}',
            '${Utils.convertToEscapeCharacter(footer.street)}',
            '${Utils.convertToEscapeCharacter(footer.websitetitle)}',
            '${Utils.convertToEscapeCharacter(footer.websiteurl)}',
            '${Utils.convertToEscapeCharacter(footer.zipcode)}'
          );`;

          ins_values.push(value);
        } else {
          let value = `
            UPDATE _footer_
            SET city='${Utils.convertToEscapeCharacter(footer.city)}',
            email='${Utils.convertToEscapeCharacter(footer.email)}',
            footer_members='${Utils.convertToEscapeCharacter(JSON.stringify(footer.footer_members))}',
            googlemapsurl='${Utils.convertToEscapeCharacter(footer.googlemapsurl)}',
            phone='${Utils.convertToEscapeCharacter(footer.phone)}',
            street='${Utils.convertToEscapeCharacter(footer.street)}',
            websitetitle='${Utils.convertToEscapeCharacter(footer.websitetitle)}',
            websiteurl='${Utils.convertToEscapeCharacter(footer.websiteurl)}',
            zipcode='${Utils.convertToEscapeCharacter(footer.zipcode)}'
            WHERE id='${id}';
          `;

          upd_values.push(value);
        }

        query.push(`CREATE TABLE IF NOT EXISTS _footer_ (
          id TEXT PRIMARY KEY NOT NULL,
          city TEXT,
          email TEXT,
          footer_members TEXT,
          googlemapsurl TEXT,
          phone TEXT,
          street TEXT,
          websitetitle TEXT,
          websiteurl TEXT,
          zipcode TEXT
        );`)

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        upd_values.forEach((upd) => {
          query.push(upd);
        });
      }

      return query;
    },

    async WORDPRESS_MENU(): Promise<string[]> {
      let query = [] as string[];

      const menus = await WordpressService.menuData();

      const menus_cached = await WordpressService.menu();

      if (menus?.length) {
        const ins_values: string[] = [];
        const upd_values: string[] = [];

        menus.map((menu) => {
          let DO_INSERT = true;

          if (menus_cached.length) {
            const mens = menus_cached.filter((mc) => mc.id === menu.id.toString());

            if (mens.length) {
              DO_INSERT = false;
            }
          }

          if (DO_INSERT) {
            let value = `INSERT INTO _menu_ (id,title,url,menu_order) VALUES (
              '${menu.id}',
              '${Utils.convertToEscapeCharacter(menu.title)}',
              '${Utils.convertToEscapeCharacter(menu.url)}',
              ${menu.menu_order}
            );`;

            ins_values.push(value);
          } else {
            let value = `
              UPDATE _menu_
              SET title='${menu.title}',
              url='${menu.url}'
              menu_order='${menu.menu_order}'
              WHERE id='${menu.id}';
            `;

            upd_values.push(value);
          }
        })

        query.push(`CREATE TABLE IF NOT EXISTS _menu_ (
          id TEXT PRIMARY KEY NOT NULL,
          title TEXT,
          url TEXT,
          menu_order INTEGER
        );`)

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        upd_values.forEach((upd) => {
          query.push(upd);
        });
      }

      return query;
    },
  },

  CREATE: {
    LAST_SYNC(name: string) {
      const date = new Date();
      return `
        CREATE TABLE IF NOT EXISTS _last_sync_ (
          id INTEGER PRIMARY KEY NOT NULL,
          name TEXT,
          date TEXT
        );

        INSERT INTO _last_sync_ (name,date) VALUES (
          '${name}',
          '${date.toISOString()}'
        );
      `;
    },
  },

  GET: {
    LAST_SYNCED: `
      SELECT * FROM _last_sync_ WHERE id IN (SELECT MAX(id) FROM _last_sync_ GROUP BY name)
    `,

    DBS: `
      SELECT name FROM sqlite_schema
    `,

    CATEGORIES(type: string): string {
      return `
        SELECT * FROM _${type}_
      `;
    },

    CATEGORY_TYPES: `
      SELECT * FROM _categoryType_
    `,

    CATEGORY_TYPE_BY_ID(id: string): string {
      return `
        SELECT * FROM _categoryType_ WHERE id='${id}'
      `;
    },

    CATEGORY_TYPE_BY_CODE(code: string): string {
      return `
        SELECT * FROM _categoryType_ WHERE code='${code}'
      `;
    },

    CATEGORY_TYPES_BY_CODES(codes: string[]): string {
      return `
        SELECT * FROM _categoryType_ WHERE code IN (${codes.map((code) => `'${code}'`).join(',')})
      `;
    },

    CATEGORIES_BY: {
      CODE(code: string): string {
        return `
          SELECT * FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = '${code}')
        `;
      },

      IDS(ids: string[]): string {
        return `
          SELECT * FROM _categories_ WHERE id IN (${ids.map((id) => `'${id}'`).join(',')}) AND category_type_id IN (SELECT id FROM _categoryType_ WHERE code IN ('primary','tag','usage','activeingredient'))
        `;
      },

      ID(id: string): string {
        return `
          SELECT * FROM _categories_ WHERE id='${id}'`;
      },
    },

    CATEGORY_SEARCH_BY_TITLE: {
      PRIMARY(title: string): string {
        return `
          SELECT id, title, orderby, 'Dyr' AS category_type_title FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'primary') AND title LIKE '${title}%' ORDER BY orderby LIMIT 10
        `;
      },

      TAG(title: string): string {
        return `
          SELECT id, title, 'Administrationsveje' AS category_type_title FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'tag') AND title LIKE '${title}%' ORDER BY title LIMIT 10
        `;
      },

      USAGE(title: string): string {
        return `
          SELECT id, title, 'Anvendelse' AS category_type_title FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'usage') AND title LIKE '${title}%' ORDER BY title LIMIT 10
        `;
      },

      ACTIVE_INGREDIENT(title: string): string {
        return `
          SELECT id, title, 'Aktive substanser' AS category_type_title FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'activeingredient') AND title LIKE '${title}%' ORDER BY title LIMIT 10
        `;
      },

      ATC(query: string, attachedToProduct: boolean): string {
        const condition = `AND id IN (SELECT category_id FROM _entityCategories_) AND item_count > 0`

        const select = `SELECT * FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'atc') AND (title LIKE '%${query}%' OR code LIKE '${query}%')`

        return `
          SELECT * FROM (${select} ${query.length > 0 ? 'ORDER BY length(code)' : ''} LIMIT 10)
            UNION
          SELECT * FROM (${select} ${attachedToProduct ? condition : ''} ORDER BY title LIMIT 10)
            ORDER BY title
        `;
      },
    },

    MEDICINES: `
      SELECT * FROM _medicine_ WHERE published = 1 ORDER BY DATETIME(updated_at) DESC;
    `,

    MEDICINES_ALL: `
      SELECT * FROM _medicine_
    `,

    MEDICINE(id: string): string {
      return `
        SELECT * FROM _medicine_ WHERE published = 1 AND id = '${id}'
      `;
    },

    MEDICINE_CHECK(id: string): string {
      return `
        SELECT * FROM _medicine_ WHERE id = '${id}'
      `;
    },

    MEDICINES_BY_PROD_ID(id: string, pageNumber: number, rowsOfPage: number): string {
      return `
        SELECT *, COUNT(*) OVER() total FROM _medicine_ WHERE published = 1 AND producer_id = '${id}' ORDER BY title
          LIMIT ${rowsOfPage} OFFSET ${(pageNumber - 1) * rowsOfPage};
      `;
    },

    MEDICINES_BY_TITLE(title: string): string {
      return `
        SELECT * FROM _medicine_ WHERE published = 1 AND title LIKE '${title}%' ORDER BY DATETIME(updated_at) DESC;
      `;
    },

    MEDICINES_BY_ID(ids: string[], producer_ids: string[]): string {
      const query = [] as string[];

      if (ids.length) {
        query.push(
          `
          SELECT * FROM _medicine_ WHERE published = 1 AND id IN (${ids
            .map((id) => `'${id}'`)
            .join(',')})
          `
        )
      }

      if (producer_ids.length) {
        query.push(
          `
          SELECT * FROM _medicine_ WHERE published = 1 AND producer_id IN (${producer_ids
            .map((id) => `'${id}'`)
            .join(',')})
          `
        )
      }

      return `${query.join(' INTERSECT ')} ORDER BY DATETIME(updated_at) DESC`;
    },

    ATCS(parent_category_id?: string): string {
      let parent_id = 'null';

      if (parent_category_id) {
        parent_id = parent_category_id;
      }

      return `
        SELECT * FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'atc') AND parent_category_id = '${parent_id}' ORDER BY code;
      `;
    },

    ATCS_BY_ID(id: string): string {
      return `
        SELECT * FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'atc') AND id = '${id}' ORDER BY code;
      `;
    },

    ATC_BY_CH_COUNT(id: string): string {
      return `
        SELECT COUNT(*) AS children_count FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'atc') AND parent_category_id = '${id}';
      `;
    },

    ATC_BY_ID(id: string): string {
      return `
        SELECT * FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'atc') AND id = '${id}' LIMIT 1
      `;
    },

    ACTIVE_INGREDIENT_BY_ID(id: string): string {
      return `
        SELECT * FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'activeingredient') AND id = '${id}' LIMIT 1
      `;
    },

    DELIVERY_REGULATIONS_BY_ID(id: string): string {
      return `
        SELECT * FROM _categories_ WHERE category_type_id IN (SELECT id FROM _categoryType_ WHERE code = 'deliveryregulation') AND id = '${id}' LIMIT 1
      `;
    },

    PRODUCER(id: string): string {
      return `
        SELECT * FROM _producer_ WHERE id = '${id}' ORDER BY title
      `;
    },

    PRODUCERS(): string {
      return `
        SELECT * FROM _producer_ ORDER BY title
      `;
    },

    PACKAGES_BY_ID(id: string): string {
      return `
        SELECT * FROM _package_ WHERE item_id = '${id}'
      `;
    },

    PACKAGES(): string {
      return `
        SELECT * FROM _package_
      `;
    },

    PACKAGE(id: string): string {
      return `
        SELECT * FROM _package_ WHERE id='${id}'
      `;
    },

    ENTITY_CATEGORIES_BY_ID(id: string): string {
      return `
        SELECT * FROM _entityCategories_ WHERE entity_id = '${id}'
        ORDER BY category_title ASC
      `;
    },

    ENTITY_CATEGORY_BY_ID(id: string): string {
      return `
        SELECT * FROM _entityCategories_ WHERE id = '${id}'
      `;
    },

    ENTITY_CATEGORIES(): string {
      return `
        SELECT * FROM _entityCategories_
        ORDER BY category_title ASC
      `;
    },

    UNIT_CATEGORIES_BY_ID(id: string): string {
      return `
        SELECT * FROM _unitCategories_ WHERE entity_id = '${id}'
      `;
    },

    UNIT_CATEGORY_BY_ID(id: string): string {
      return `
        SELECT * FROM _unitCategories_ WHERE id = '${id}'
      `;
    },

    SMPC(id: string): string {
      return `
        SELECT * FROM _smpc_ WHERE id = '${id}'
      `;
    },

    SMPCS(): string {
      return `
        SELECT * FROM _smpc_
      `;
    },

    COMPANY(id: string): string {
      return `
        SELECT * FROM _producer_ WHERE id = '${id}'
      `;
    },

    COMPANIES(): string {
      return `
        SELECT * FROM _producer_
      `;
    },

    MEDICINES_SEARCH(title: string, cat_ids: string[], producer_id: string, pageNumber: number, rowsOfPage: number, orderByTitle?: boolean): string {
      const conditions: string[] = [];

      if (title.length) {
        conditions.push(`title LIKE '${title}%'`);
      }

      if (cat_ids.length) {
        conditions.push(`id IN (SELECT entity_id FROM _entityCategories_ WHERE category_id IN (${cat_ids.map((id) => `'${id}'`).join(',')}) GROUP BY entity_id ${cat_ids.length > 1 ? `HAVING COUNT(entity_id) = ${cat_ids.length}` : ''})`);
      }

      if (producer_id.length) {
        conditions.push(`producer_id = '${producer_id}'`);
      }

      return `
        SELECT *, COUNT(*) OVER() total FROM _medicine_
         ${conditions.length ? `WHERE published = 1 AND ${conditions.join(' AND ')} ORDER BY ${orderByTitle ? 'title, promoted DESC' : 'promoted DESC, title'}` : 'WHERE published = 1 ORDER BY DATETIME(updated_at) DESC'}
          LIMIT ${rowsOfPage} OFFSET ${(pageNumber - 1) * rowsOfPage};
      `
    },

    ATC_MEDICINES_SEARCH(cat_ids: string[], pageNumber: number, rowsOfPage: number): string {
      const conditions: string[] = [];

      if (cat_ids.length) {
        conditions.push(`id IN (SELECT entity_id FROM _entityCategories_ WHERE category_id IN (${cat_ids.map((id) => `'${id}'`).join(',')}) GROUP BY entity_id ${cat_ids.length > 1 ? `HAVING COUNT(entity_id) = ${cat_ids.length}` : ''})`);
      }

      return `
        SELECT *, COUNT(*) OVER() total FROM _medicine_
         ${conditions.length ? `WHERE ${conditions.join(' AND ')} ORDER BY title, promoted DESC` : 'ORDER BY DATETIME(updated_at) DESC'}
          LIMIT ${rowsOfPage} OFFSET ${(pageNumber - 1) * rowsOfPage};
      `
    },

    ATC_BRANCH_ITEM_COUNT(code: string): string {
      return `
        SELECT COUNT(*) as branch_item_count FROM _entityCategories_ WHERE category_type_code = 'atc' AND category_code LIKE '${code}%'
      `
    },

    SEARCH_QUERY(id: string): string {
      return `
        SELECT * FROM _search_query_ WHERE id = '${id}'
      `;
    },

    FOOTER(id: string): string {
      return `
        SELECT * FROM _footer_ WHERE id = '${id}'
      `;
    },

    MENU: `
      SELECT * FROM _menu_ ORDER BY menu_order
    `,
  },

  INSERT: {
    async SEARCH_QUERY(searchQuery: SearchQuery): Promise<string[]> {
      let query = [] as string[];

      const searchQuery_cached = await BackStepService.searchQuery(searchQuery.id);

      if (searchQuery) {
        const ins_values: string[] = [];
        const upd_values: string[] = [];

        let DO_INSERT = true;

        if (searchQuery_cached) {
          if (Object.keys(searchQuery_cached).length) {
            DO_INSERT = false;
          }
        }

        if (DO_INSERT) {
          let value = `INSERT INTO _search_query_ (id,categories,producer,query) VALUES (
              '${searchQuery.id}',
              '${Utils.convertToEscapeCharacter(JSON.stringify(searchQuery.categories))}',
              '${Utils.convertToEscapeCharacter(JSON.stringify(searchQuery.producer))}',
              '${searchQuery.query}'
          );`;

          value = value
            .replace(/\n/g, '')

          ins_values.push(value);
        } else {
          let value = `
            UPDATE _search_query_
            SET categories='${Utils.convertToEscapeCharacter(JSON.stringify(searchQuery.categories))}',
            producer='${Utils.convertToEscapeCharacter(JSON.stringify(searchQuery.producer))}',
            query='${searchQuery.query}'
            WHERE id='${searchQuery.id}';
          `;

          value = value
            .replace(/\n/g, '')

          upd_values.push(value);
        }

        query.push(`
          CREATE TABLE IF NOT EXISTS _search_query_ (
            id TEXT PRIMARY KEY NOT NULL,
            categories TEXT,
            producer TEXT,
            query TEXT
          );
        `);

        ins_values.forEach((ins) => {
          query.push(ins);
        });

        upd_values.forEach((upd) => {
          query.push(upd);
        });
      }

      return query;
    },
  },
};

export default QUERY;
