<template>
  <div class="sponsors">
    <div class="wrapper">
      <div class="title">VORES MEDLEMMER:</div>
      <div class="members">
        <template v-for="(member, i) in contacts.footer_members" :key="i">
          <a :href="member.footer_member_url" target="_blank">
            <img
              :src="member.footer_member_logo"
              :alt="member.footer_member_name"
            />
          </a>
        </template>
      </div>
    </div>
  </div>
  <div class="footer">
    <img
      :src="require('@/assets/images/vinordic_logo.png')"
      alt="Logo"
      class="logo"
    />
    <div class="contacts">
      <a :href="contacts.googlemapsurl" target="_blank">{{
        contacts.street
      }}</a>
      | {{ contacts.zipcode }} <span v-html="contacts.city"></span> |
      <a :href="contacts.websiteurl" target="_blank">{{
        contacts.websitetitle
      }}</a>
      | Telefon: <a :href="`tel:${contacts.phone}`">{{ contacts.phone }}</a> |
      Mail:
      <a :href="`mailto:${contacts.email}`">{{ contacts.email }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Footer } from "@/models/wordpress";
import { store } from "@/store";

export default defineComponent({
  name: "FooterComponent",
  computed: {
    contacts(): Footer {
      return store.state.contacts;
    }
  },
});
</script>

<style lang="scss" scoped>
.footer {
  box-sizing: border-box;
  background-color: #eb3d47;
  padding: 30px 16px;

  .logo {
    margin-bottom: 35px;
    width: 148px;
    height: 22px;
  }

  .contacts {
    font-family: "OpenSans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;

    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.sponsors {
  background-color: #f8f8f8;

  .wrapper {
    padding: 30px 0;
  }

  .title {
    padding: 0 16px;
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 16px;
    font-family: "OpenSans", sans-serif;
    color: #595959;
  }

  .members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    img {
      width: 72px;
      margin: 16px;
    }
  }
}
</style>
