<template>
  <ion-modal
    :is-open="state"
    :swipe-to-close="false"
    :presenting-element="element"
    cssClass="modal-manager"
    @didDismiss="$emit('modal:close')"
  >
      <div class="modal-body">
        <div class="modal-header">
          <div class="modal-title">Søg i ATC-Register</div>
          <div class="modal-actions">
            <div
              class="button atc-back"
              v-if="currentStack > 0"
              @click="resetStack"
            >
              <font-awesome-icon class="icon" :icon="['fas', 'chevron-left']" />
            </div>
            <div class="button atc-close" @click="closeModal">
              <ion-img
                class="icon"
                :src="require('@/assets/Icons/other/PNG/close.png')"
                alt="Close"
              />
            </div>
          </div>
        </div>

        <div class="sub-header">
          <div class="atc-toggle">
            <div class="title">
              Aktive ATC kode
            </div>
            <Toggle v-model:state="attachedToProduct"/>
          </div>

          <div class="atc-search">
            <ATCDropdown
              class="dropdown"
              v-model="selectedAtc"
              v-model:search-input="atcSearchInput"
              :items="atcs"
              placeholder="Søg efter ATC-kode"
            />
          </div>
        </div>

        <template v-if="breadcrumbs.length">
          <div class="breadcrumbs">
            <span
              class="breadcrumb"
              v-for="(breadcrumb, index) in breadcrumbs"
              :key="`bd-${breadcrumb.id}`"
              @click="onClickBreadcrumbHandler(index)"
              :title="breadcrumb.value"
            >
              {{ breadcrumb.value }}
            </span>
          </div>
        </template>

        <div class="atc-menu" ref="stacks">
          <template
            v-for="(stack, index) in stacksVisible"
            :key="`stack-${stack}`"
          >
          <template v-if="isEmpty[stack]">
            <div class="empty-view">
              <div class="header">Der er ingen resultater fundet</div>
              <div class="loader">
                <img
                  src="@/assets/images/default-placeholder.svg"
                  alt="Loader image"
                />
              </div>
            </div>
          </template>
            <template v-else-if="buffer[stack]">
              <atc-sidebar
                :class="`stack-${stack} ${
                  stacksVisible.length > 2 && index === 1
                    ? 'stack-centered'
                    : stacksVisible.length > 1 && index === 1
                    ? 'stack-even'
                    : ''
                }`"
                @drug-selected="addToStack($event, stack, index)"
                @drug-route="redirectToProductView($event)"
                :drugs="buffer[stack]"
                :selected="breadcrumbs"
                v-if="buffer[stack].length > 0"
              />
            </template>
          </template>
        </div>
      </div>
  </ion-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  IonModal,
  IonImg,
  useIonRouter,
} from '@ionic/vue';
import CategoryService from '@/services/CategoryService';
import AtcSidebar from '@/components/Atc/AtcSidebar.vue';
import Toggle from '../ui/Toggle.vue';
import ATCDropdown from '../ui/form/ATCDropdown.vue';
import { ATC, Breadcrumb, ATC_LEVELS } from '@/models/category';
import { Drug, EntityCategory } from '@/models/medicine';
import MedicineService from '@/services/MedicineService';
import AnalyticsService from '@/services/AnalyticsService';

export default defineComponent({
  name: 'ATCRegister',
  setup() {
    const router = useIonRouter();
    return { router };
  },
  components: {
    AtcSidebar,
    IonModal,
    IonImg,
    Toggle,
    ATCDropdown,
  },
  props: {
    state: {
      type: Boolean,
      default: false,
      required: true,
    },
    element: {
      type: Element,
      required: true,
    },
    preSelectedAtcId: {
      type: String,
    }
  },
  data() {
    return {
      stack: [] as (ATC | Drug)[][],
      buffer: [] as (ATC | Drug)[][],
      breadcrumbs: [] as Breadcrumb[],
      currentStack: 0 as number,
      levels: [
        ATC_LEVELS.LEVEL_1,
        ATC_LEVELS.LEVEL_2,
        ATC_LEVELS.LEVEL_3,
        ATC_LEVELS.LEVEL_4,
        ATC_LEVELS.LEVEL_5,
        ATC_LEVELS.LEVEL_6,
      ] as ATC_LEVELS[],
      lastStack: 0 as number,
      stacksVisible: [] as number[],
      visibleStack: 0 as number,
      isEmpty: [false, false, false, false, false, false] as boolean[],
      entCats: [] as EntityCategory[],
      atcSearchInput: '' as string,
      selectedAtc: {} as ATC,
      atcs: [] as ATC[],
      attachedToProduct: true as boolean,
      searchTimeoutId: 0 as number,
    };
  },
  emits: ['modal:close'],
  async mounted() {
    const entCats = await MedicineService.entityCategories();
    if (entCats.length) {
      this.entCats = entCats;
    }

    this.getStacks();

    const atcs: ATC[] = await CategoryService.atcsByQuery('', this.attachedToProduct);
    this.atcs = atcs.filter((atc) => this.attachedToProduct ? atc.branch_item_count > 0 : true )
  },
  watch: {
    async atcSearchInput(val) {
      if (!/\S/.test(val) && val !== "") {
        return;
      }

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        const atcs: ATC[] = await CategoryService.atcsByQuery(val, this.attachedToProduct);
        this.atcs = atcs.filter((atc) => this.attachedToProduct ? atc.branch_item_count > 0 : true )
      }, 300);
    },
    async attachedToProduct(val: boolean) {
      const atcs: ATC[] = await CategoryService.atcsByQuery('', val);
      this.atcs = atcs.filter((atc) => val ? atc.branch_item_count > 0 : true )
      this.buildAtcTree(undefined);
      this.selectedAtc = {} as ATC
    },
    async selectedAtc(val: ATC) {
      if (Object.keys(val).length > 0) {
        await this.buildAtcTree(val.id);
        this.selectedAtc = {} as ATC
      }
    },
    async state(val: boolean) {
      if (!val) {
        this.attachedToProduct = true;
        this.reset();
      } else {
        this.buildAtcTree(this.preSelectedAtcId)
      }

      await AnalyticsService.trackScreenView(`ATC Register - ${val ? 'Opened' : 'Closed'}`);
    }
  },
  methods: {
    reset(): void {
      this.buffer = [];
      this.stack = [];
      this.breadcrumbs = [];
      this.stacksVisible = [];
      this.isEmpty = [false, false, false, false, false, false];
      this.currentStack = 0;
      this.lastStack = 0;
      this.visibleStack = 0;
    },
    async buildAtcTree(atcId?: string): Promise<void> {
      this.reset();

      if (atcId) {
          const {tree, breadcrumbs} = await CategoryService.atcsTree(atcId, this.attachedToProduct);

          this.getStacks();

          let index = 0;
          let isExists = false

          breadcrumbs.forEach((breadcrumb) => {
            if (!isExists) {

              this.buffer.push(tree[index]);
              this.stack.push(tree[index]);

              this.addToPreFetchStack(breadcrumb, this.currentStack, index)

              isExists = breadcrumb.code === this.selectedAtc.code

              index++
            }
          })

          this.buffer.push(tree[index]);
          this.stack.push(tree[index]);
      } else {
        const atcs = await CategoryService.atc();
        const filteredAtcs = atcs.filter((atc) => this.attachedToProduct ? atc.branch_item_count > 0 : true )
        this.buffer.push(filteredAtcs);
        this.stack.push(filteredAtcs);
        this.getStacks();
      }
    },
    redirectToProductView(productId: string) {
      this.$emit('modal:close');

      this.router.push({
        name: 'Product',
        params: {
          productId,
        },
      });
    },
    scrollBack(): void {
      const source = this.$refs.stacks as HTMLElement;
      source.scroll({
        top: 0,
        behavior: 'smooth',
      });
    },
    getStacks() {
      const page = 1;

      const chunked = Array.from({ length: Math.ceil(this.levels.length / page) }, (val, i) => (
        this.levels.slice(i * page, i * page + page)
      ));

      this.lastStack = chunked[this.visibleStack].length;

      this.stacksVisible = chunked[this.visibleStack];
    },
    addToPreFetchStack(atc: ATC, stack: number, index: number): void {
      this.isEmpty[stack + 1] = atc.children_count === 0 && atc.item_count === 0;

      if (stack < this.currentStack) {
        this.$nextTick(() => {
          this.breadcrumbs.splice(stack);
          this.buffer.splice(stack + 1);
        });
      }

      if (this.lastStack <= index + 1) {
        this.visibleStack += 1;
        this.getStacks();
      }

      this.breadcrumbs[stack] = {
        id: atc.id,
        value: `${atc.code} ${atc.title}`,
        branch_item_count: atc.branch_item_count,
      };

      this.currentStack = stack + 1;
    },
    async addToStack(atc: ATC, stack: number, index: number): Promise<void> {
      this.scrollBack();

      this.isEmpty[stack + 1] = false;

      if (stack < this.currentStack) {
        this.$nextTick(() => {
          this.breadcrumbs.splice(stack);
          this.buffer.splice(stack + 1);
        });
      }

      if (this.lastStack <= index + 1) {
        this.visibleStack += 1;
        this.getStacks();
      }

      const atcs: ATC[] = await CategoryService.atc(atc.id);
      const filteredAtcs = atcs.filter((atc) => this.attachedToProduct ? atc.branch_item_count > 0 : true )

      const newStack: (ATC | Drug)[] = [];

      const drugs = await MedicineService.atc_medicines_search(
        [atc.id],
        1,
        50,
      );

      if (filteredAtcs.length <= 0) {
        if (drugs.length <= 0) {
          this.isEmpty[stack + 1] = true;
        }
      }

      newStack.push(...filteredAtcs);

      if (drugs.length > 0) {
        newStack.push(...drugs);
      }

      this.breadcrumbs[stack] = {
        id: atc.id,
        value: `${atc.code} ${atc.title}`,
        branch_item_count: atc.branch_item_count,
      };

      this.currentStack = stack + 1;
      this.buffer[stack + 1] = newStack;
    },
    resetStack() {
      if (this.currentStack > 0) {
        this.buffer[this.currentStack] = [];
        this.isEmpty[this.currentStack] = false;
        this.currentStack -= 1;
        this.breadcrumbs.pop();
      }
      if (this.currentStack <= this.stacksVisible[0] && this.visibleStack > 0) {
        this.visibleStack -= 1;
        this.getStacks();
      }
    },
    closeModal() {
      this.$emit('modal:close');
    },
    onClickBreadcrumbHandler(position: number) {
      if ((position + 1) !== this.currentStack) {
        for (let index = this.currentStack; index >= (position + 2); index -= 1) {
          this.resetStack();
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.modal-body {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;

    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 17px;

      .modal-title {
        font-size: 24px;
        font-weight: 900;
        line-height: 33px;
        color: #EB3D47;
        white-space: nowrap;
      }

      @media screen and (max-width: 340px) {
        .modal-title {
          white-space: normal;
        }
      }

      .modal-actions {
        display: flex;
        align-items: center;
        position: static;

        .button {
          cursor: pointer;

          &.atc-back {
            width: 26px;
            height: 26.48px;
            margin-right: 10px;
            background-color: #EB3D47;
            color: #FFFFFF;
            font-size: 12px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.atc-close {
            width: 28px;
            .icon {
              height: 100%;
            }
          }
        }

      }
    }

    .sub-header {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .atc-toggle {
        margin-right: 20px;
        .title {
          margin-bottom: 4px;
          color: black;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .atc-search {
        flex: 1;
      }
    }

    .breadcrumbs {
      margin-right: 0;
      padding: 0 10px;
      margin-bottom: 24px;
      line-height: 16px;
      font-size: 11px;

      .breadcrumb {
        display: inline-flex;
        align-items: center;
        color: #595959;
        cursor: pointer;
      }

      :not(:last-child):after {
        content: '';
        width: 12px;
        height: 1px;
        margin: 0 8px;
        display: inline-flex;
        background-color: #595959;;
      }

      :last-child {
        color: #EB3D47;
      }
    }

    .atc-menu {
      display: flex;
      align-items: flex-start;
      max-height: 800px;
      overflow-y: auto;
      margin: 0;
      padding-right: 10px;

      &::-webkit-scrollbar{
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-thumb{
        background: #EB3D47;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track{
        background: transparent;
        border-radius: 10px;
        box-shadow: inset 0 0 2px 0 #5959599c;
      }

      .stack-centered {
        margin: 0 20px;
      }

      .stack-even {
        margin-left: 20px;
      }

      .menu, .empty-view {
        width: 100%;
        min-width: auto;
        max-width: 100%;
      }

      .empty-view {

        .header {
          font-size: 16px;
          color: #EB3D47;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          border-bottom: 1px solid #93939329;
        }

        .loader {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 200px;
          }
        }
      }
    }
  }
</style>
