<template>
  <ion-app>
    <transition @after-enter="onEnter">
      <template v-if="!isLoading">
        <div id="page-wrapper" ref="pageWrapper">
          <ion-router-outlet class="ion-router" />
        </div>
      </template>
    </transition>
    <transition @before-leave="onLeave">
      <pre-loader
        v-if="isLoading"
        :progress="progress"
        :isLoading="isLoading"
      />
    </transition>
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import PreLoader from "@/components/ui/PreLoader.vue";
import { store } from "@/store";
import { ACTIONS } from "@/models/store";
import { Utils } from "@/utils/utils";
import Migration from "@/utils/migration";
import AnalyticsService from "./services/AnalyticsService";
import { App } from "@capacitor/app";
import { DEBUG } from "./models/utils";
import CacheService from "./services/CacheService";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    PreLoader,
  },
  data: () => ({
    isLoading: true,
    result: {},
  }),
  async created() {
    await Utils.toggleStatusBarTheme(true);
    await store.dispatch(ACTIONS.DATABASE_OPEN);
    await CacheService.removeOldDBFiles();
    await AnalyticsService.init_analytics();
    await Migration.run();
    await Migration.cacheWordpressApi();
  },
  async mounted() {
    await App.addListener('appStateChange', async ({ isActive }) => {
      Utils.debug(`App state changed ::Is active: ${isActive}`, DEBUG.MESSAGE)

      if (isActive) {
        await this.onStateChangeHandler();
      }
    });
  },
  async unmounted() {
    await App.removeAllListeners();
  },
  computed: {
    progress(): number {
      return store.state.progress;
    },
  },
  watch: {
    progress(value: number) {
      if (value === 100) {
        setTimeout(() => {
          this.isLoading = false;
        }, 100);
      }
    },
  },
  methods: {
    onEnter(): void {
      const element = this.$refs.pageWrapper as HTMLElement;
      element.style.opacity = "1";
    },
    onLeave(element: HTMLElement): void {
      element.style.opacity = "0";
    },
    async onStateChangeHandler(): Promise<void> {
      await Migration.cacheWordpressApi();
    }
  },
});
</script>

<style lang="scss" scoped>
#page-wrapper {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.ion-page {
  justify-content: normal;
}

.main {
  overflow: scroll;
  height: 100%;
}
</style>
