import axios, { AxiosInstance } from 'axios';
import { Utils } from './utils';

const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    'Content-type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: Utils.getRequestTimeout(),
});

httpClient.interceptors.response.use((response) => response.data);

export default httpClient;
