export interface CONFIG {
  NAME: string;
  IS_ENCRYPTED: boolean;
  MODE: string;
  VERSION: number;
  READONLY: boolean;
}

export const DB_CONFIG: CONFIG = {
  NAME: process.env.VUE_APP_DATABASE_NAME,
  IS_ENCRYPTED: process.env.VUE_APP_DATABASE_ENCRYPTION === '1',
  MODE: process.env.VUE_APP_DATABASE_MODE,
  VERSION: Number(process.env.VUE_APP_DATABASE_VERSION),
  READONLY: process.env.VUE_APP_DATABASE_READONLY === 'true',
}
