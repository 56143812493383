import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-039bd134"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["isMainNavOpen"]
const _hoisted_2 = {
  key: 0,
  class: "nav-items"
}
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, { class: "ion-no-border" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, {
        translucent: true,
        id: "header",
        color: _ctx.isFiltersVisible && !_ctx.isMainNavOpen ? 'light' : 'primary'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, {
            class: "nav-toggle",
            slot: "start"
          }, {
            default: _withCtx(() => [
              (!_ctx.isMainNavOpen && _ctx.canToggleFilters)
                ? (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 0,
                    class: "icon",
                    slot: "icon-only",
                    icon: _ctx.isFiltersVisible ? _ctx.closeSharp : _ctx.optionsSharp,
                    onClick: _ctx.onClickFilterToggleHandler
                  }, null, 8, ["icon", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_buttons, {
            class: "nav-toggle",
            slot: "end"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                class: "icon",
                slot: "icon-only",
                icon: _ctx.isMainNavOpen ? _ctx.closeSharp : _ctx.menuSharp,
                onClick: _ctx.onClickNavigationHandler
              }, null, 8, ["icon", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_title, { onClick: _ctx.onClickHeaderHandler }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_img, {
                class: "header-logo",
                src: require('@/assets/images/logo.png')
              }, null, 8, ["src"])
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["color"]),
      _createElementVNode("nav", {
        id: "navigation",
        isMainNavOpen: _ctx.isMainNavOpen
      }, [
        (_ctx.isMainNavOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (link) => {
                return (_openBlock(), _createElementBlock("a", {
                  key: link,
                  class: "nav-item",
                  href: link.url,
                  target: "_blank",
                  rel: "noopener noreferrer"
                }, _toDisplayString(link.title), 9, _hoisted_3))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }))
}