import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Utils } from "@/utils/utils";
import { DEBUG } from "@/models/utils";
import { Capacitor } from "@capacitor/core";
import { EVENT } from "@/models/analytics";

class AnalyticsService {
  private static isAnalyticsEnabled: boolean = process.env.VUE_APP_ANALYTICS_ENABLED === 'true';

  static async init_analytics(): Promise<void> {
    const platform = Capacitor.getPlatform();

    const isWeb = platform === 'web';

    if (!isWeb) {
      await FirebaseAnalytics.setCollectionEnabled({ enabled: this.isAnalyticsEnabled });
      await FirebaseAnalytics.enable();
    }

    if (!this.isAnalyticsEnabled) {
      Utils.debug('Tracking Disabled', DEBUG.WARNING)
      return
    }

    if (isWeb) {
      this.clearCookies();

      const firebaseConfig = {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
      };

      if (Object.values(firebaseConfig).includes(undefined)) {
        Utils.debug('Web Analytics Disabled', DEBUG.WARNING)
        return
      }

      await FirebaseAnalytics.initializeFirebase(firebaseConfig);
    }

    Utils.debug('Analytics Initliazed', DEBUG.INFO)
  }

  static clearCookies() {
    const cookies = document.cookie.split(";");

    cookies.forEach(function (c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    Utils.debug('All Cookies Cleared', DEBUG.SUCCESS)
  }

  static async trackEvent(action: EVENT, eventParams?: any | undefined): Promise<void> {
    if (!this.isAnalyticsEnabled) {
      Utils.debug('Tracking Disabled', DEBUG.WARNING)
      return
    }

    await FirebaseAnalytics.logEvent({
      name: action,
      params: eventParams
    })

    Utils.debug(`Tracking Event ${action} With Params ${JSON.stringify(eventParams)}`, DEBUG.MESSAGE)
  }

  static async trackScreenView(...names: string[]): Promise<void> {
    if (!this.isAnalyticsEnabled) {
      Utils.debug('Tracking Disabled', DEBUG.WARNING)
      return
    }

    const name = names.join(' ').slice(0, 39);

    await FirebaseAnalytics.setScreenName({
      screenName: name,
      nameOverride: name.toUpperCase(),
    });

    await FirebaseAnalytics.logEvent({
      name: 'page_view',
      params: {
        name,
      },
    });

    Utils.debug(`Tracking Screen View ${name}`, DEBUG.MESSAGE)
  }
}

export default AnalyticsService
