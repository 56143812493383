import { Capacitor } from '@capacitor/core';
import {
  CapacitorSQLite,
  capSQLiteValues,
  SQLiteConnection,
} from '@capacitor-community/sqlite';
import { DB_CONFIG } from '@/models/sqlite';
import { ACTIONS, MUTATIONS, State } from '@/models/store';
import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store, MutationTree, ActionTree } from 'vuex';
import { Utils } from '@/utils/utils';
import { DEBUG } from '@/models/utils';
import { Footer } from '@/models/wordpress';
import WordpressService from '@/services/WordpressService';

export const key: InjectionKey<Store<State>> = Symbol();

/*
 * State
 */
const state: State = {
  progress: 0,
  navigation: {
    filters: false,
  },
  IS_DB_COPIED: false,
  IS_DB_OPENED: false,
  DATABASE: null,
  SQLITE: null,
  contacts: {} as Footer,
  menu: [],
  canDoPageReload: true,
};

/*
 * Mutations
 */
const mutations: MutationTree<State> = {
  [MUTATIONS.SET_TOGGLE_FILTERS](state, payload: boolean): void {
    state.navigation.filters = payload
  },
  [MUTATIONS.SET_PRELOAD_PROGRESS](state, payload: number): void {
    state.progress = payload
  },
  [MUTATIONS.SET_TOGGLE_PAGE_RELOAD](state, payload: boolean): void {
    state.canDoPageReload = payload
  },
};

/*
 * Actions
 */
const actions: ActionTree<State, capSQLiteValues | any | void> = {
  async [ACTIONS.DATABASE_OPEN]({ state }): Promise<void> {
    if (!state.IS_DB_OPENED) {
      try {
        const platform = Capacitor.getPlatform();
        state.SQLITE = new SQLiteConnection(CapacitorSQLite);

        if (platform === 'web') {
          const jeepSqlite = document.createElement('jeep-sqlite');
          document.body.appendChild(jeepSqlite);
          await customElements.whenDefined('jeep-sqlite');
          await state.SQLITE.initWebStore();
        } else {
          if (!state.IS_DB_COPIED) {
            try {
              Utils.debug('Copying DB From Assets', DEBUG.MESSAGE);
              await state.SQLITE.copyFromAssets(false);
            } catch (error) {
              Utils.debug(`Copying DB Error: ${error}`, DEBUG.ERROR);
            } finally {
              state.IS_DB_COPIED = true;
            }
          }
        }

        const isConsistent = await state.SQLITE.checkConnectionsConsistency();

        const isExists = await state.SQLITE.isConnection(DB_CONFIG.NAME, DB_CONFIG.READONLY);

        if (isConsistent.result && isExists.result) {
          state.DATABASE = await state.SQLITE.retrieveConnection(DB_CONFIG.NAME, DB_CONFIG.READONLY);
        } else {
          state.DATABASE = await state.SQLITE.createConnection(
            DB_CONFIG.NAME,
            DB_CONFIG.IS_ENCRYPTED,
            DB_CONFIG.MODE,
            DB_CONFIG.VERSION,
            DB_CONFIG.READONLY
          );
        }

        await state.DATABASE.open();
        state.IS_DB_OPENED = true;
      } catch (error) {
        Utils.debug(`App DATABASE_OPEN Error: ${error}`, DEBUG.ERROR);
      }
    }
  },

  async [ACTIONS.DATABASE_CLOSE]({ state }): Promise<void> {
    try {
      await state.SQLITE?.closeConnection(DB_CONFIG.NAME, DB_CONFIG.READONLY);
      state.IS_DB_OPENED = false;
    } catch (error) {
      Utils.debug(`App Error: ${error}`, DEBUG.ERROR);
    }
  },

  async [ACTIONS.EXECUTE_QUERY]({ state }, payload) {
    try {
      await state.DATABASE?.execute(payload);
    } catch (error) {
      Utils.debug(`App EXECUTE_QUERY Error: ${error}`, DEBUG.ERROR);
    }
  },

  async [ACTIONS.RUN_QUERY]({ state }, payload): Promise<capSQLiteValues> {
    let result: capSQLiteValues;

    Utils.debug(`Executing Query: ${(payload as string).slice(0, 60).replace(/\n/g, '')}`, DEBUG.WARNING);

    try {
      result = await state.DATABASE?.query(payload) as capSQLiteValues;
      return result;
    } catch (error) {
      Utils.debug(`App RUN_QUERY Error: ${error}`, DEBUG.ERROR);
      return { values: [] } as capSQLiteValues;
    }
  },

  async [ACTIONS.CACHE_MENU]({ state }): Promise<void> {
    try {
      state.menu = await WordpressService.menu();
    } catch (error) {
      Utils.debug(`Wordpress CACHE_MENU Error: ${error}`, DEBUG.ERROR);
    }
  },
 
  async [ACTIONS.CACHE_FOOTER]({ state }): Promise<void> {
    try {
      const id = process.env.VUE_APP_WORDPRESS_API_CACHE_KEY;

      const contacts = await WordpressService.footer(id);

      if (!contacts) return;

      await contacts.footer_members.reduce(async (promise, member) => {
        await promise;

        member.footer_member_logo = await Utils.cacheImage(member.footer_member_name, member.footer_member_logo)

      }, Promise.resolve());

      state.contacts = contacts;
    } catch (error) {
      Utils.debug(`Wordpress CACHE_FOOTER Error: ${error}`, DEBUG.ERROR);
    }
  },
};

export const store = createStore<State>({ state, mutations, actions });

// our own useStore function for types
export const useStore = () => baseUseStore(key);
