import { SearchQuery } from "@/models/backstep";
import { ACTIONS } from "@/models/store";
import { store } from "@/store";
import QUERY from "@/utils/query";


class BackStepService {
  static async searchQuery(id: string): Promise<SearchQuery> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.SEARCH_QUERY(id));

    const searchQuery = response.values[0];

    if (searchQuery) {
      searchQuery.categories = searchQuery.categories === '[]' ? [] : JSON.parse(searchQuery.categories);
      searchQuery.producer = searchQuery.producer === 'undefined' ? {} : JSON.parse(searchQuery.producer);
    }

    return searchQuery;
  }
}

export default BackStepService