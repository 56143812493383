import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faChevronLeft,
  faChevronRight,
  faExternalLinkAlt,
  faInfoCircle as fasInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTimes,
  faTimes as faTimesLight,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faSearch,
} from '@fortawesome/pro-light-svg-icons';

import {
  faEye,
  faDownload,
  faFilePdf,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  fasInfoCircle,
  faChevronLeft,
  faChevronRight,
  faExternalLinkAlt,
  faTimes,
  faTimesLight,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faSearch,
  faEye,
  faDownload,
  faFilePdf,
);
