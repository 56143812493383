<template>
  <div class="ui-scroll">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import OverlayScrollbars from 'overlayscrollbars';

export default defineComponent({
  name: 'UiScroll',
  mounted() {
    OverlayScrollbars(document.querySelectorAll('.ui-scroll'), {
      overflowBehavior: {
        x: 'scroll',
      },
      sizeAutoCapable: true,
    });
  },
});
</script>

<style lang="scss">
.os-scrollbar-handle {
  width: 6px;
  background: #ea4650 !important;
  border-radius: 10px;
}
</style>
