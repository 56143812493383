<template>
  <div
    class="atc-dropdown"
    :class="{ focus: focus, disabled: disabled, 'read-only': readOnly }"
  >
    <div class="atc-dropdown-box">
      <input
        :value="searchInput"
        @input="input = $event.target.value;"
        :placeholder="computedPlaceholder"
        @focus="focus = true"
        @blur="focus = false"
        class="atc-dropdown-input"
        :disabled="disabled || readOnly"
      />
      <template v-if="!disabled && !readOnly">
        <div class="atc-dropdown-icon" @click.stop="(!focus && model.id) ? (model = {}) : ''">
          <font-awesome-icon
            :class="(!focus && model.id) ? 'remove' : 'search'"
            :icon="['fal', (!focus && model.id) ? 'times' : 'angle-down']"
          />
        </div>
      </template>
    </div>
    <div v-if="focus" class="atc-dropdown-dropdown">
      <div class="no-result" v-if="!items.length">Ingen resultat</div>
      <div v-else>
        <ui-scroll>
          <template v-for="(item, i) in items" :key="`item-${i}`">
            <div
              class="dropdown-item"
              :class="{ selected: isSelected(item.id) }"
              @mousedown="selectItem(item)"
            >
              <div class="title" :title="item.title">
                {{ item.title }}
              </div>
              <div class="sub-title">{{ item.code }}</div>
            </div>
          </template>
        </ui-scroll>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UiScroll from '@/components/ui/form/UiScroll.vue';
import { store } from '@/store';
import { MUTATIONS } from "@/models/store";

export default defineComponent({
  name: 'ATCDropdown',
  props: {
    modelValue: {
      type: Object,
    },
    searchInput: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    focus: false,
  }),
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
    input: {
      get(): string {
        return this.searchInput;
      },
      set(val: string) {
        this.$emit('update:search-input', val);
      },
    },
    computedPlaceholder(): string {
      return this.model.title || this.placeholder;
    },
  },
  components: {
    UiScroll,
  },
  watch: {
    focus(val) {
      if (!val) this.input = '';
      store.commit(MUTATIONS.SET_TOGGLE_PAGE_RELOAD, !val)
    },
  },
  methods: {
    selectItem(item: any) {
      if (this.model.id === item.id) {
        this.model = {};
        return;
      }

      this.model = item;
      this.input = '';
    },
    isSelected(id: string) {
      return this.model.id === id;
    },
  },
});
</script>

<style lang="scss" scoped>
.atc-dropdown {
  width: 100%;
  height: 50px;
  background: #f8f8f8;
  color: #595959;
  position: relative;
  border-radius: 10px;
  font-size: 16px;

  &.focus {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      position: absolute;
      bottom: 0;
      z-index: -1;
      background-color: #edebeb;
    }
  }

  ::placeholder {
    font-weight: 400;
    font-family: 'OpenSans', sans-serif;
    font-size: 14px;
    color: #595959;
  }

  &.focus,
  &:hover {
    background: #edebeb;
  }

  &.focus {
    ::placeholder {
      font-weight: 400;
      color: #9d9d9d;
    }
  }

  &.disabled:not(.read-only) {
    opacity: 0.3;
    user-select: none;
    pointer-events: none !important;
  }

  &.read-only {
    background: #fff;
  }

  .atc-dropdown-dropdown {
    border-radius: 0 0 10px 10px;
    background: #f8f8f8;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 10;

    .no-result {
      padding: 0 10px;
      height: 50px;
      font-weight: 400;
      font-size: 14px;
      line-height: 50px;
    }

    .ui-scroll {
      max-height: 250px;
    }

    .dropdown-item {
      box-sizing: border-box;
      width: 100%;
      cursor: pointer;
      padding: 8px 15px;

      &:hover,
      &.selected {
        background: #edebeb;
      }

      .title {
        width: 100%;
        margin: 0 0 5px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
      }

      .sub-title {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        color: #9e9e9e;
      }
    }
  }

  .atc-dropdown-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .atc-dropdown-input {
      position: relative;
      background-color: transparent;
      font-size: 14px;
      font-family: 'OpenSans', sans-serif;
      font-weight: 400;
      text-overflow: ellipsis;
      color: #595959;
      padding: 0 15px;
      height: 50px;
      width: 100%;
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      box-sizing: border-box;
      cursor: default;

      &:focus {
        outline: none;
      }
    }

    .atc-dropdown-icon {
      width: 50px;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      svg {
        color: #595959;
        font-size: 15px;

        &.remove {
          cursor: pointer;
        }

        &.search {
          pointer-events: none;
        }
      }
    }
  }
}
</style>
