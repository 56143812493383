<template>
  <ion-header class="ion-no-border">
    <ion-toolbar
      :translucent="true"
      id="header"
      :color="isFiltersVisible && !isMainNavOpen ? 'light' : 'primary'"
    >
      <ion-buttons class="nav-toggle" slot="start">
        <ion-icon
          v-if="!isMainNavOpen && canToggleFilters"
          class="icon"
          slot="icon-only"
          :icon="isFiltersVisible ? closeSharp : optionsSharp"
          @click="onClickFilterToggleHandler"
        />
      </ion-buttons>
      <ion-buttons class="nav-toggle" slot="end">
        <ion-icon
          class="icon"
          slot="icon-only"
          :icon="isMainNavOpen ? closeSharp : menuSharp"
          @click="onClickNavigationHandler"
        ></ion-icon>
      </ion-buttons>

      <ion-title @click="onClickHeaderHandler">
        <ion-img
          class="header-logo"
          :src="require('@/assets/images/logo.png')"
        />
      </ion-title>
    </ion-toolbar>

    <nav id="navigation" :isMainNavOpen="isMainNavOpen">
      <div class="nav-items" v-if="isMainNavOpen">
        <template v-for="link in menu" :key="link">
          <a
            class="nav-item"
            :href="link.url"
            target="_blank" rel="noopener noreferrer"
          >
            {{ link.title }}
          </a>
        </template>
      </div>
    </nav>
  </ion-header>
</template>

<script lang="ts">
import { Menu } from '@/models/wordpress';
import { store } from '@/store';
import { Utils } from '@/utils/utils';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle,
  IonImg,
  useIonRouter,
} from '@ionic/vue';
import { optionsSharp, menuSharp, closeSharp } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  setup() {
    const router = useIonRouter();
    return { optionsSharp, menuSharp, closeSharp, router };
  },
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonTitle,
    IonImg,
  },
  emits: ['navigation:toggle', 'filters:toggle'],
  data: () => ({
    isMainNavOpen: false,
    filtersToggle: false,
  }),
	computed: {
    menu(): Menu[] {
      return store.state.menu
    },
		isFiltersVisible(): boolean {
			return store.state.navigation.filters;
		},
    canToggleFilters(): boolean {
      const currentRoute = this.$route.name as string;
      const allowedPaths = ['Home', 'Inventory'];

      return allowedPaths.includes(currentRoute);
    },
    baseAppUrl() {
      return process.env.VUE_APP_BASE_APP_URL;
    },
	},
  watch: {
    isFiltersVisible(val: boolean) {
      this.filtersToggle = val
    },
  },
	methods: {
    onClickHeaderHandler(): void {
      const routeName = this.$route.name
      const isHome = routeName === 'Home' || routeName === 'Inventory'

      this.router.push({
        name: 'Home',
        query: isHome ? this.$route.query : {}
      });
      this.isMainNavOpen = false
    },
    async onClickNavigationHandler(): Promise<void> {
      await Utils.toggleStatusBarTheme((!this.filtersToggle || !this.isMainNavOpen));
			this.isMainNavOpen = !this.isMainNavOpen;
		},
		async onClickFilterToggleHandler(): Promise<void> {
			this.filtersToggle = !this.filtersToggle;
      await Utils.toggleStatusBarTheme(!this.filtersToggle);
			store.commit('SET_TOGGLE_FILTERS', this.filtersToggle);
		},
  },
});
</script>

<style lang="scss">
ion-title.title-default {
  display: inherit;
}
</style>

<style lang="scss" scoped>
#header {
  min-height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  transition: color 200ms ease-in-out;

  .header-logo {
    max-width: 155px;
    margin: 0 auto;
  }

  .icon {
    font-size: 32px;
    padding: 4px 16px;
  }

  .nav-toggle {
    min-width: 64px;
  }
}

#navigation {
  position: fixed;
  background-color: #eb3d47;
  width: 100%;
  height: 0;
  transition: height 200ms ease-in-out;
	overflow: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }

  &[isMainNavOpen="true"] {
    height: calc(100% - 60px);
    overflow-y: scroll;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 10px;

    .nav-item {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
      color: white;
      padding: 10px;
      margin: 10px auto;
      cursor: pointer;
      text-decoration: none;

      &:active {
        opacity: 0.75;
      }
    }
  }
}
</style>
