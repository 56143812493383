<template>
  <div class="pre-loader">
    <ion-img
      :class="`preloader-image ${progress === 100 ? 'big' : ''}`"
      :src="require('@/assets/images/logo.png')"
    />
    <transition @before-leave="onLeave">
      <div class="loader" v-if="progress !== 100">
        <ion-progress-bar color="light" :value="progress/100"></ion-progress-bar>
        <div class="status">
          {{progress}} %
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {
  IonProgressBar,
  IonImg,
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PreLoader',
  components: {
    IonProgressBar,
    IonImg,
  },
  props: {
    progress: {
      type: Number,
      required: true,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  emits: ['update:progress', 'update:isLoading'],
  methods: {
    onLeave(element: HTMLElement) {
      element.style.height = '0';
      element.style.opacity = '0';
    },
  }
});
</script>

<style lang="scss" scoped>
.pre-loader {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  background-color: #EB3D47;
  transition: opacity 0.5s ease-out;
  color: white;

  .title {
    font-size: 20px;
    font-weight: bolder;

    &.big {
      font-size: 30px;
    }
  }

  .preloader-image {
    height: 20px;
    transition: transform 0.2s ease-in-out;

    &.big {
      transform: scale(1.4);
    }
  }

  .loader {
    width: 60%;
    margin: 0 auto;
    transition: all .5s ease-in-out;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .status {
      font-size: 14px;
      font-weight: bolder;
      opacity: 0.75;
    }
  }
}
</style>