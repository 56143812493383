import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index';
import { IonicVue } from '@ionic/vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { defineCustomElements as jeepSqlite, applyPolyfills } from 'jeep-sqlite/loader';

import '@/font-awesome-icons';
import '@/styles'

applyPolyfills().then(() => {
  jeepSqlite(window);
});

window.addEventListener('DOMContentLoaded', () => {
  const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon);

  try {
    router.isReady().then(() => {
      app.mount('#app');
    });

  } catch (error) {
    throw new Error(`Error: ${error}`);
  }
});
