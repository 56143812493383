import { store } from '@/store';
import { ACTIONS } from '@/models/store';
import QUERY from '@/utils/query';
import { Utils } from '@/utils/utils';
import { DEBUG } from '@/models/utils';

const Migration = {
  async run() {
    let LAST_SYNCED: any | undefined;

    try {
      LAST_SYNCED = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.LAST_SYNCED);
    } catch (error) {
      LAST_SYNCED = undefined;
    }

    const GET_LAST_SYNCED = (name: string) => LAST_SYNCED?.values.filter((data: any) => data.name === name)[0]?.date;

    const CACHE_TABLES = Object.values(QUERY.CACHE);

    Utils.debug('Starting Migration', DEBUG.INFO);

    await CACHE_TABLES.reduce(async (promise, CACHE_QUERY, INDEX) => {
      await promise;

      const cacheTableName: string = Object.keys(QUERY.CACHE)[INDEX];

      Utils.debug(`Running ${cacheTableName} Queries`, DEBUG.INFO);

      const date: string | undefined = GET_LAST_SYNCED(cacheTableName);

      const QUERIES = await CACHE_QUERY(date);

      const progressBar = Math.round(((INDEX + 1) / CACHE_TABLES.length) * 100)

      if (QUERIES.length) {
        await QUERIES.reduce(async (promise, query) => {
          await promise;

          if (query.length) {
            await store.dispatch(ACTIONS.EXECUTE_QUERY, query);
          }
        }, Promise.resolve())

        await this.sync(cacheTableName);

        Utils.debug(`Executed ${QUERIES.length} ${cacheTableName} Queries`, DEBUG.MESSAGE);
      } else {
        Utils.debug(`Skipping ${cacheTableName} Queries`, DEBUG.MESSAGE);
      }

      this.updateProgress(progressBar);

    }, Promise.resolve());

    Utils.debug('Ending Migration', DEBUG.MESSAGE);
  },

  async sync(name: string) {
    await store.dispatch(ACTIONS.EXECUTE_QUERY, QUERY.CREATE.LAST_SYNC(name));
  },

  async cacheWordpressApi(): Promise<void> {
    await store.dispatch(ACTIONS.CACHE_MENU);
    await store.dispatch(ACTIONS.CACHE_FOOTER);
  },

  async updateProgress(value: number) {
    store.commit('SET_PRELOAD_PROGRESS', value);
  },
};

export default Migration;
