import { Drug } from "./medicine"

export interface ATC {
  category_type_id: string
  code: string
  created_at: string
  deleted_at: string | null
  details: []
  id: string
  item_count: number
  children_count: number
  branch_item_count: number
  parent_category_id: string | null
  title: string
  updated_at: string
}

export interface PreSelectedATCS {
  tree: (ATC | Drug)[][];
  breadcrumbs: ATC[];
}

export interface Breadcrumb {
  id: string
  value: string
  branch_item_count: number
}

export enum ATC_LEVELS {
  LEVEL_1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  LEVEL_5,
  LEVEL_6,
}

export interface CategoryType {
  id: string,
  code: string,
  title: string,
  created_at: string,
  updated_at: string,
  deleted_at: string,
}

export interface Category {
  id: string,
  category_type_id: string,
  category_type_title: string,
  parent_category_id: string,
  code: string,
  title: string,
  details: { icon: string, order: number }
  item_count: number,
  orderby?: number,
  created_at: string,
  updated_at: string,
  deleted_at: string,
}