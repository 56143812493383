<template>
  <ion-toggle color="primary" v-model="model" mode="ios"></ion-toggle>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import {
  IonToggle,
} from '@ionic/vue';

export default defineComponent({
  name: 'Toggle',
  components: {
    IonToggle,
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    model: {
      get(): boolean {
        return this.state;
      },
      set(val: boolean) {
        this.$emit('update:state', val);
      },
    },
  },
});
</script>

<style scoped>
  ion-toggle {
    height: 34px;
    width: 64px;

    --handle-width: 26px;
    --handle-height: 27px;
    --handle-max-height: auto;
    --handle-spacing: 5px;

    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
    contain: none;
  }

  ion-toggle::part(track) {
    background: #c9c9c9;
  }

  ion-toggle.toggle-checked::part(track) {
    background: #eb3d47;
  }

  ion-toggle::part(handle) {
    background: white;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    box-shadow: none;
  }

</style>
