import { Params, ApiResponse } from '@/models/api';
import httpClient from '@/utils/http.client';
import {
  Drug, EntityCategory, Package, Producer,
} from '@/models/medicine';
import { store } from '@/store';
import { ACTIONS } from '@/models/store';
import QUERY from '@/utils/query';

class MedicineService {
  static async medicine(id: string): Promise<Drug> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.MEDICINE(id)));

    const product = response.values[0];

    if (product) {
      product.details = JSON.parse(product.details);
      product.images = JSON.parse(product.images);
      product.files = JSON.parse(product.files);
    }

    return product;
  }

  static async medicineCheck(id: string): Promise<Drug> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.MEDICINE_CHECK(id)));

    const product = response.values[0];

    if (product) {
      product.details = JSON.parse(product.details);
      product.images = JSON.parse(product.images);
      product.files = JSON.parse(product.files);
    }

    return product;
  }

  static async producer(id: string): Promise<Producer> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.PRODUCER(id)));

    const producer = response.values[0];

    if (producer) {
      producer.details = JSON.parse(producer.details);
    }

    return producer;
  }

  static async packages_by_id(id: string): Promise<Package[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.PACKAGES_BY_ID(id)));

    const pack = response.values as any[];

    if (pack) {
      pack.map((pk) => {
        pk.details = JSON.parse(pk.details);
      })
    }

    return pack;
  }

  static async packages(): Promise<Package[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.PACKAGES()));

    const pack = response.values as any[];

    if (pack) {
      pack.map((pk) => {
        pk.details = JSON.parse(pk.details);
      })
    }

    return pack;
  }

  static async package(id: string): Promise<Package> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.PACKAGE(id)));

    const pack = response.values[0];

    if (pack) {
      pack.details = JSON.parse(pack.details);
    }

    return pack;
  }

  static async entityCategoriesById(id: string): Promise<EntityCategory[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ENTITY_CATEGORIES_BY_ID(id)));

    const ec = response.values as any[];

    return ec;
  }

  static async entityCategoryById(id: string): Promise<EntityCategory> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ENTITY_CATEGORY_BY_ID(id)));

    return response.values[0];
  }

  static async entityCategories(): Promise<EntityCategory[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ENTITY_CATEGORIES()));

    const ec = response.values as any[];

    return ec;
  }

  static async unitCategoriesById(id: string): Promise<EntityCategory[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.UNIT_CATEGORIES_BY_ID(id)));

    const uc = response.values as any[];

    return uc;
  }

  static async unitCategoryById(id: string): Promise<EntityCategory> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.UNIT_CATEGORY_BY_ID(id)));

    return response.values[0];
  }

  static async medicinesByTitle(title: string): Promise<Drug[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.MEDICINES_BY_TITLE(title)));

    const products = response.values as any[];

    if (products.length) {
      products.map((product) => (
        product.details = JSON.parse(product.details)
      ));
    }
    return products;
  }

  static async medicinesByProdId(id: string, pageNumber: number, rowsOfPage: number): Promise<Drug[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.MEDICINES_BY_PROD_ID(id, pageNumber, rowsOfPage)));

    const products = response.values as any[];

    if (products.length) {
      products.map((product) => (
        product.details = JSON.parse(product.details)
      ));
    }
    return products;
  }

  static async medicinesById(ids: string[], producer_ids?: string[]): Promise<Drug[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.MEDICINES_BY_ID(ids, producer_ids ? producer_ids : [])));

    const products = response.values as any[];

    if (products.length) {
      products.map((product) => (
        product.details = JSON.parse(product.details)
      ));
    }
    return products;
  }

  static async producers(): Promise<Producer[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.PRODUCERS()));

    const producers = response.values as any[];

    if (producers.length) {
      producers.map((producer) => {
        producer.details = JSON.parse(producer.details);
      })
    }

    return producers;
  }


  static async medicines(): Promise<Drug[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.MEDICINES));

    const products = response.values as any[];

    if (products.length) {
      products.map((product) => {
        product.details = JSON.parse(product.details);

        return product;
      });
    }

    return products;
  }

  static async medicinesAll(): Promise<Drug[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.MEDICINES_ALL));

    const products = response.values as any[];

    if (products.length) {
      products.map((product) => {
        product.details = JSON.parse(product.details);

        return product;
      });
    }

    return products;
  }

  static async medicines_search(title: string, ids: string[], producer_id: string, pageNumber: number, rowsOfPage: number, orderByTitle?: boolean): Promise<Drug[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.MEDICINES_SEARCH(title, ids, producer_id, pageNumber, rowsOfPage, orderByTitle)));

    const products = response.values as any[];

    if (products.length) {
      products.map((product) => {
        product.details = JSON.parse(product.details);

        return product;
      });
    }

    return products;
  }

  static async atc_medicines_search(ids: string[], pageNumber: number, rowsOfPage: number): Promise<Drug[]> {
    const response = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ATC_MEDICINES_SEARCH(ids, pageNumber, rowsOfPage)));

    const products = response.values as any[];

    if (products.length) {
      products.map((product) => {
        product.details = JSON.parse(product.details);

        return product;
      });
    }

    return products;
  }

  static async medicineData(params: Params): Promise<ApiResponse<Drug> | null> {
    let response: ApiResponse<Drug> | null;

    try {
      response = await httpClient.get('/api/mobile/products', { params });
    } catch (error) {
      response = null;
    }

    return response;
  }

  static async producerData(params: Params): Promise<ApiResponse<Producer> | null> {
    let response: ApiResponse<Producer> | null;

    try {
      response = await httpClient.get('/api/mobile/products/producers', { params });
    } catch (error) {
      response = null;
    }

    return response;
  }

  static async packageData(params: Params): Promise<ApiResponse<Package> | null> {
    let response: ApiResponse<Package> | null;

    try {
      response = await httpClient.get('/api/mobile/products/units', { params });
    } catch (error) {
      response = null;
    }

    return response;
  }

  static async entityCategoryData(params: Params): Promise<ApiResponse<EntityCategory> | null> {
    let response: ApiResponse<EntityCategory> | null;

    try {
      response = await httpClient.get('/api/mobile/products/categories', { params });
    } catch (error) {
      response = null;
    }

    return response;
  }

  static async unitCategoryData(params: Params): Promise<ApiResponse<EntityCategory> | null> {
    let response: ApiResponse<EntityCategory> | null;

    try {
      response = await httpClient.get('/api/mobile/products/units/categories', { params });
    } catch (error) {
      response = null;
    }

    return response;
  }

}

export default MedicineService;
