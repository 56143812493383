import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b439e71c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "page-wrapper",
  ref: "pageWrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_pre_loader = _resolveComponent("pre-loader")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_Transition, { onAfterEnter: _ctx.onEnter }, {
        default: _withCtx(() => [
          (!_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_router_outlet, { class: "ion-router" })
              ], 512))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onAfterEnter"]),
      _createVNode(_Transition, { onBeforeLeave: _ctx.onLeave }, {
        default: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_pre_loader, {
                key: 0,
                progress: _ctx.progress,
                isLoading: _ctx.isLoading
              }, null, 8, ["progress", "isLoading"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onBeforeLeave"])
    ]),
    _: 1
  }))
}