import { Params, ApiResponse } from '@/models/api';
import httpClient from '@/utils/http.client';
import {
  ATC, Category, CategoryType, PreSelectedATCS,
} from '@/models/category';
import { store } from '@/store';
import { ACTIONS } from '@/models/store';
import QUERY from '@/utils/query';
import { Drug } from '@/models/medicine';
import MedicineService from './MedicineService';

class CategoryService {
  static async atc(id?: string): Promise<ATC[]> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ATCS(id));

    const transformedAtcs: ATC[] = []

    await (response.values as ATC[]).reduce(async (promise, atc) => {
      await promise;

      const { children_count, branch_item_count } = await this.getItemCount(atc);

      const temp = atc

      temp.children_count = children_count;
      temp.branch_item_count = branch_item_count;

      transformedAtcs.push(temp)
    }, Promise.resolve())

    return transformedAtcs;
  }

  static async atcsById(id: string): Promise<ATC[]> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ATCS_BY_ID(id));

    const transformedAtcs: ATC[] = []

    await (response.values as ATC[]).reduce(async (promise, atc) => {
      await promise;

      const { children_count, branch_item_count } = await this.getItemCount(atc);

      const temp = atc

      temp.children_count = children_count;
      temp.branch_item_count = branch_item_count;

      transformedAtcs.push(temp)
    }, Promise.resolve())

    return transformedAtcs;
  }

  static async getItemCount(atc: ATC): Promise<{ children_count: number, branch_item_count: number }> {
    const { children_count } = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ATC_BY_CH_COUNT(atc.id))).values[0];
    const { branch_item_count } = (await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ATC_BRANCH_ITEM_COUNT(atc.code))).values[0];

    return { children_count, branch_item_count };
  }

  static async atcById(id: string): Promise<ATC> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ATC_BY_ID(id));

    return response.values[0];
  }

  static async activeIngredientById(id: string): Promise<ATC> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ACTIVE_INGREDIENT_BY_ID(id));

    return response.values[0];
  }

  static async atcsTree(id: string, attachedToProduct: boolean): Promise<PreSelectedATCS> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.ATC_BY_ID(id));

    const atc = response.values[0] as ATC;

    const { children_count, branch_item_count } = await this.getItemCount(atc);

    atc.children_count = children_count;
    atc.branch_item_count = branch_item_count;

    let parent_category_id = atc.parent_category_id as string;

    const atcsPreSelected = [] as ATC[];
    const atcsTree = [] as (ATC | Drug)[][];

    await Array.from(Array(5)).reduce(async (promise) => {
      await promise;

      if (parent_category_id !== 'null') {
        const atcs = await this.atcsById(parent_category_id);

        parent_category_id = atcs[0].parent_category_id as string;

        if (atcs.length) {
          atcsPreSelected.push(atcs[0]);
        }
      }
    }, Promise.resolve());

    atcsPreSelected.reverse();

    atcsPreSelected.push(atc);

    const addToStack = async (atcId: string | undefined, atcMedId: string) => {
      const atcs = await this.atc(atcId);
      const filteredAtcs = atcs.filter((atc) => attachedToProduct ? atc.branch_item_count > 0 : true )

      const drugs = await MedicineService.atc_medicines_search(
        [atcMedId],
        1,
        50,
      );

      const atcItems: ATC[] = [];
      const drugItems: Drug[] = [];

      if (filteredAtcs.length) {
        atcItems.push(...filteredAtcs);
      }

      if (drugs.length > 0) {
        drugItems.push(...drugs)
      }

      atcsTree.push([...atcItems, ...drugItems]);
    }

    await atcsPreSelected.reduce(async (promise, atc, index) => {
      await promise;
      await addToStack(atc.parent_category_id ? atc.parent_category_id : undefined, atcsPreSelected[index - 1] ? atcsPreSelected[index - 1].id : '')
    }, Promise.resolve())

    await addToStack(atc.id, atc.id)

    const result: PreSelectedATCS = {
      tree: atcsTree,
      breadcrumbs: atcsPreSelected
    }

    return result;
  }

  static async atcsByQuery(title: string, attachedToProduct: boolean): Promise<ATC[]> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORY_SEARCH_BY_TITLE.ATC(title, attachedToProduct));
    const transformedAtcs: ATC[] = []

    await (response.values as ATC[]).reduce(async (promise, atc) => {
      await promise;

      const { children_count, branch_item_count } = await this.getItemCount(atc);

      const temp = atc

      temp.children_count = children_count;
      temp.branch_item_count = branch_item_count;

      transformedAtcs.push(temp)
    }, Promise.resolve())

    return transformedAtcs;
  }

  static async deliveryRegulationsById(id: string): Promise<Category[]> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.DELIVERY_REGULATIONS_BY_ID(id));

    return response.values;
  }

  static async categoriesByQuery(type: string, title: string): Promise<Category[]> {
    const categories: Category[] = [];

    switch (type) {
      case 'main': {
        const primaryCats = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORY_SEARCH_BY_TITLE.PRIMARY(title));

        const tagCats = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORY_SEARCH_BY_TITLE.TAG(title));

        const usageCats = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORY_SEARCH_BY_TITLE.USAGE(title));

        const actCats = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORY_SEARCH_BY_TITLE.ACTIVE_INGREDIENT(title));

        if (primaryCats.values.length) {
          categories.push(...primaryCats.values)
        }

        if (tagCats.values.length) {
          categories.push(...tagCats.values)
        }

        if (usageCats.values.length) {
          categories.push(...usageCats.values)
        }

        if (actCats.values.length) {
          categories.push(...actCats.values)
        }

        break;
      }

      default: {
        const priCats = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORY_SEARCH_BY_TITLE.PRIMARY(title));

        categories.push(...priCats.values)

        break;
      }
    }

    return categories;
  }

  static async categoryById(id: string): Promise<Category> {
    const response2 = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORIES_BY.ID(id));

    return response2.values[0];
  }

  static async categoriesByCode(code: string): Promise<Category[]> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORIES_BY.CODE(code));

    return response.values;
  }

  static async categoriesById(ids: string[]): Promise<Category[]> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORIES_BY.IDS(ids));

    return response.values;
  }

  static async categoryTypes(): Promise<CategoryType[]> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORY_TYPES);

    return response.values;
  }

  static async categoryTypeById(id: string): Promise<CategoryType> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORY_TYPE_BY_ID(id));

    return response.values[0];
  }

  static async categoryTypeByCode(code: string): Promise<CategoryType> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORY_TYPE_BY_CODE(code));

    return response.values[0];
  }

  static async categoryTypesByCodes(codes: string[]): Promise<CategoryType[]> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.CATEGORY_TYPES_BY_CODES(codes));

    return response.values[0];
  }

  static async categoryTypesData(params: Params): Promise<ApiResponse<CategoryType> | null> {
    let response: ApiResponse<CategoryType> | null;

    try {
      response = await httpClient.get('/api/mobile/category_types', { params });
    } catch (error) {
      response = null;
    }

    return response;
  }

  static async categoryData(params: Params): Promise<ApiResponse<Category> | null> {
    let response: ApiResponse<Category> | null;

    try {
      response = await httpClient.get('/api/mobile/categories', { params });
    } catch (error) {
      response = null;
    }

    return response;
  }
}

export default CategoryService;
