<template>
  <page element_name="search" ref="page" @reload:page="onReloadPageHandler()" @load:more="loadMore()">
    <ui-search
      id="search"
      class="search"
      v-model="search.main"
      v-model:search-input="mainSearchInput"
      :items="categories.main"
      placeholder="Søg produkt, aktiv substans, anvendelsesområde, administrationsvej, firma"
    />
    <div ref="filterContainer" class="filters" :open="isFiltersVisible" style="display: none;">
      <ui-combobox
        v-model="search.main"
        v-model:search-input="primarySearchInput"
        class="dropdown"
        itemText="title"
        itemValue="id"
        :items="categories.primary"
        variant="red"
        placeholder="Dyr"
        :multiple="true"
      />

      <div class="button outline margin-b" :disabled="!isFiltersActive" @click="isFiltersActive ? clearFilters() : ''">
        Ryd søgning
      </div>

      <div class="button outline" @click="atcModal = true">
        Søg i ATC-Register
      </div>
    </div>
    <template v-if="!isFiltersVisible">
      <div class="button outline margin-b" :disabled="!isFiltersActive" @click="isFiltersActive ? clearFilters() : ''">
        Ryd søgning
      </div>
    </template>

    <template v-if="isSearchActive && (!isSearching || products.length ? true : false)">
      <div class="product-list-header">
        Der er {{ total }} resultat(er) på din søgning...
      </div>
    </template>
    <template v-else>
      <template v-if="products.length && !isSearching">
        <div class="latest-products">
          Senest opdaterede produkter
        </div>
      </template>
      <template v-else>
        <div :class="`search-loader`" >
          <ion-img class="image" :src="require('@/assets/images/search_bg.png')" />

          <template v-if="isSearching && !products.length">
            <div class="header">
              Loading...
            </div>
          </template>
          <template v-else>
            <div class="header">
              Din søgning gav desværre intet resultat
            </div>
          </template>
        </div>
      </template>
    </template>

    <div class="product-list" v-if="!isSearching || products.length ? true : false">
      <template v-for="(product, index) in products" :key="`product-${index}`">
        <div class="product">
          <div class="drug-card" @click="redirectToProductView(product.id)">
            <div class="details">
              <div class="title">{{ product.title }}</div>
              <div class="dosage">{{ product.dosage }}</div>
              <div class="strength">{{ product.strength }}</div>
              <div class="activeingredient">{{ product.activeingredient }}</div>
              <div class="producer">{{ product.producer }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <atc-register
      :state="atcModal"
      @modal:close="atcModal = false"
      :element="$parent.$refs.ionRouterOutlet"
    />
  </page>
</template>

<script lang="ts">
import UiSearch from "@/components/ui/form/UiSearch.vue";
import Page from "@/components/Page.vue";
import AtcRegister from "@/components/Atc/AtcRegister.vue";
import { IonImg, useIonRouter } from "@ionic/vue";
import { defineComponent } from "vue";
import { store } from "@/store";
import { ACTIONS, MUTATIONS } from "@/models/store";
import MedicineService from "@/services/MedicineService";
import {
  Drug,
  EntityCategory,
  Producer,
  TransformedDrug,
} from "@/models/medicine";
import CategoryService from "@/services/CategoryService";
import UiCombobox from "@/components/ui/form/UiCombobox.vue";
import { Category } from "@/models/category";
import { Entity, SearchQuery } from "@/models/backstep";
import QUERY from '@/utils/query';
import BackStepService from "@/services/BackStepService";
import { Utils } from "@/utils/utils";
import AnalyticsService from "@/services/AnalyticsService";
import { DEBUG } from "@/models/utils";
import { Capacitor } from '@capacitor/core';

export default defineComponent({
  name: "Inventory",
  setup() {
    const router = useIonRouter();
    return { router };
  },
  components: {
    IonImg,
    UiSearch,
    AtcRegister,
    Page,
    UiCombobox,
  },
  data: () => ({
    atcModal: false,
    mainSearchInput: "",
    products: [] as TransformedDrug[],
    categories: {
      main: [] as Category[],
      primary: [] as Category[],
    } as any,
    primarySearchInput: "" as string,
    search: {
      main: [],
    } as any,
    searchTimeoutId: 0,
    queryTimeoutId: 0,
    isSearching: false,
    producers: [] as Producer[],
    entCats: [] as EntityCategory[],
    producerFilter: [] as any[],
    currentPage: 1,
    perPage: 10,
    total: 0,
    isLoaded: false,
    allLoaded: false,
    isViewLoaded: false,
    categoryOrder: 0,
    isLoadingMore: false,
  }),
  async ionViewWillEnter() {
    await this.fetch();
    this.isViewLoaded = true;
    await this.searchInventory();
  },
  ionViewWillLeave() {
    store.commit(MUTATIONS.SET_TOGGLE_FILTERS, false);
    this.allLoaded = false;
    this.currentPage = 0;
  },
  computed: {
    isFiltersActive(): boolean {
      return this.search.main.length > 0 || this.mainSearchInput.length > 0;
    },
    isSearchActive(): boolean {
      return (this.filters.length !== 0 || this.producerSearch.length !== 0 || this.mainSearchInput !== '') && this.total !== 0
    },
    isFiltersVisible(): boolean {
      const filterState = store.state.navigation.filters

      this.animateFilters(filterState);

      return filterState;
    },
    filters(): any[] {
      let filters: any[] = []

      const main: any[] = this.search.main.filter(
          (cat: any) => cat.category_type_title != "FIRMA"
        );

      filters.push(...main);

      filters = filters.map((cat: any) => cat.id)
                .filter((cat: any) => cat != null);

      return filters
    },
    producerSearch(): any[] {
      return this.search.main.filter(
        (cat: any) => cat.category_type_title === "FIRMA"
      );
    },
    totalPages(): number {
      if(this.total === 0) {
        return 1;
      }

      return Math.ceil(this.total / this.perPage);
    },
    searchQueryId(): string {
      return process.env.VUE_APP_SEARCH_QUERY_CACHE_KEY;
    }
  },
  watch: {
    isFiltersVisible(state: boolean) {
      const filterContainer = this.$refs.filterContainer as HTMLElement;

      if (filterContainer) {
        if (state) {
          setTimeout(() => {
            filterContainer.style.overflow = 'visible';
          }, 200);
        } else {
          filterContainer.style.overflow = 'hidden';
        }
      }

      (this.$refs.page as any).scrollTo();
    },
    async primarySearchInput(val) {
      if (!/\S/.test(val) && val !== "") {
        return;
      }

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        this.categories.primary = await CategoryService.categoriesByQuery(
          "primary",
          val,
        );
      }, 300);
    },
    async mainSearchInput(val) {
      if (!/\S/.test(val) && val !== "") {
        return;
      } else if(val !== "") {
        await this.searchInventory();
      }

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        // this.products = [] as TransformedDrug[];
        this.currentPage = 1;
        const categories = await CategoryService.categoriesByQuery(
          "main",
          val,
        );

        this.categories.main = [...categories];

        const producerFilters = this.producerFilter.filter((producer: any) =>
          producer.title.toLowerCase().includes(val.toLowerCase())
        );
        this.categories.main.push(...producerFilters);

        await this.searchInventory();
      }, 300);
    },
    filters: {
      deep: true,
      async handler() {
        if (this.allLoaded) {
          // this.products = [] as TransformedDrug[];
          this.currentPage = 1;
          await this.searchInventory();
        }
      },
    },
  },
  methods: {
    async onReloadPageHandler() {
      this.isViewLoaded = false;
      await this.fetch();
      this.isViewLoaded = true;
    },
    animateFilters(state: boolean): void {
      const filterContainer = this.$refs.filterContainer as HTMLElement;

      if (filterContainer) {
        setTimeout(() => {
          filterContainer.style.display = state ? 'block' : 'none';
        }, state ? 0 : 350);
      }
    },
    async loadMore(): Promise<void> {
      this.isLoadingMore = true;
      if (this.isSearchActive) {
        if (!this.isLoaded) {
          if (this.currentPage !== this.totalPages) {
            this.currentPage = this.currentPage + 1;
            await this.searchInventory(true);
          }
        }
      }
      this.isLoadingMore = false;
    },
    async fetch(): Promise<void> {
      this.isLoaded = true;

      this.search.main = [];
      this.mainSearchInput = '';

      const searchQuery: SearchQuery = await BackStepService.searchQuery(this.searchQueryId);

      if (searchQuery) {
        const query = this.$route.query ? this.$route.query : {};

        if (Object.keys(searchQuery).length !== 0) {
          if (!Object.keys(query).length) {
            this.constructUrl(searchQuery.categories, searchQuery.producer, searchQuery.query);
          }
        }
      }

      const producers = await MedicineService.producers();

      if (producers.length) {
        this.producers = producers;
        this.producerFilter = producers.map((producer) => {
          return {
            id: producer.id,
            title: producer.title,
            category_type_title: "FIRMA",
          };
        });
      }

      const entCats = await MedicineService.entityCategories();
      if (entCats.length) {
        this.entCats = entCats;
      }

      await Object.keys(this.categories).reduce(async (promise, cats) => {
        await promise;

        this.categories[cats] = await CategoryService.categoriesByQuery(
          cats,
          "",
        );
      }, Promise.resolve());

      this.categories.main.push(...this.producerFilter);

      const query = Object.keys(this.$route.query).length ? this.$route.query : {};

      if (Object.keys(query).length) {
        if (query.categoryId) {
          const isString = typeof query.categoryId === 'string'

          const categoryIds = isString ? [(query.categoryId as string)] : (query.categoryId as string[])

          const categories = await CategoryService.categoriesById(categoryIds);

          if (categories.length) {
            await categories.reduce(async (promise, cat) => {
              await promise
              await this.invokeCategory(cat, searchQuery)
            }, Promise.resolve())
          }
        }

        if (query.producerId) {
          const producerId = query.producerId as string;

          const producer = await MedicineService.producer(producerId);

          const searchedProducer = searchQuery.producer;

          if (producer) {
            this.search.main.push({
              id: producer.id,
              title: producer.title,
              category_type_title: 'FIRMA',
              SEARCH_ORDER: searchedProducer ? searchedProducer.SEARCH_ORDER : 0,
            })
          }
        }

        if (query.searchQuery) {
          this.mainSearchInput = query.searchQuery as string
        }
      }
    },
    async invokeCategory(category: Category, searchQuery: SearchQuery): Promise<void> {

      let searchedCategory: Entity | undefined = undefined;

      if (searchQuery) {
        searchedCategory = searchQuery.categories.filter((cat: Entity) => cat.id === category.id)[0];
      }

      const obj = {
        id: category.id,
        title: category.title,
        category_type_title: category.category_type_title,
        SEARCH_ORDER: searchedCategory ? searchedCategory.SEARCH_ORDER : 0,
      }

      this.search.main.push(obj)
    },
    async searchInventory(more?: boolean): Promise<void> {
      if (!this.isSearching) {
        if (!this.isLoadingMore) {
          this.isSearching = true;
        }

        if (this.isViewLoaded) {
          await this.searchQuery();
        }

        try {
          const page = this.$refs.page as any;

          const producer: any = this.producerSearch[0];

          const products = await MedicineService.medicines_search(
            this.mainSearchInput,
            this.filters,
            producer ? producer.id : '',
            this.currentPage,
            this.perPage,
          );

          if (products.length) {
            const temp = [] as TransformedDrug[];
            await products.reduce(async (promise, drug: Drug) => {
              await promise;
              this.total = drug.total;
              temp.push(await this.transformDrugForEdit(drug));
            }, Promise.resolve());

            if (more) {
              this.products = this.products.concat(temp);
            } else {
              this.products = temp;
            }
          } else {
            this.total = 0;
            this.products = [] as TransformedDrug[];
          }

          if (page) {
            page.setMinThreshold(
              338.77 * (Math.round(this.products.length / 2) - 1)
            );
          }
        } catch (error) {
          throw new Error(`App Error: ${error}`);
        } finally {
          if (!this.allLoaded) {
            setTimeout(() => {
              (this.$refs.page as any).scrollToTop();
            }, 100);
          }

          this.isSearching = false
          this.isLoaded = false;
          this.allLoaded = true;
        }
      }
    },
    async searchQuery(): Promise<void> {
      if (!this.isViewLoaded) {
        return;
      }

      const main = this.search.main.map((cat: any, index: number) => {
        if (!cat.SEARCH_ORDER) {
          cat.SEARCH_ORDER = index;
          return cat;
        } else {
          return cat;
        }
      });

      const query = this.mainSearchInput;

      const categories = main.filter((c: any) => c.category_type_title !== 'FIRMA') as Entity[];

      const producer = main.filter((c: any) => c.category_type_title === 'FIRMA')[0] as Entity;

      window.clearTimeout(this.queryTimeoutId);

      this.queryTimeoutId = window.setTimeout(async () => {
        const searchQuery: SearchQuery = {
          id: this.searchQueryId,
          categories,
          producer,
          query,
        }

        const QUERIES = await QUERY.INSERT.SEARCH_QUERY(searchQuery);

        await store.dispatch(ACTIONS.EXECUTE_QUERY, QUERIES.join(' '));

        const updatedSQ = await BackStepService.searchQuery(this.searchQueryId);

        this.constructUrl(updatedSQ.categories, updatedSQ.producer, updatedSQ.query);

        await this.onProductSearchLogHandler();
      }, 300);
    },
    async redirectToProductView(productId: string): Promise<void> {
      await this.onProductSearchLogHandler();

      this.router.push({
        name: "Product",
        params: {
          productId,
        },
      });

    },
    async onProductSearchLogHandler(): Promise<void> {
      const platform = Capacitor.getPlatform();

      if (platform === 'web') {
        return;
      }

      try {
        const updatedSQ = await BackStepService.searchQuery(this.searchQueryId);

        if(updatedSQ) {
          const searchData = {
            categories: updatedSQ.categories.length? updatedSQ.categories.map((cat) => cat.title).join(', ').slice(0, 12):'null',
            'producer': Object.keys(updatedSQ.producer).length? updatedSQ.producer.title.slice(0, 12):'null',
            'search_keyword': updatedSQ.query? updatedSQ.query.slice(0, 12):'null',
          };

          const constraint = Object.keys(updatedSQ).length&&!(Object.values(searchData).every((val) => val==='null'));

          if(constraint) {
            await AnalyticsService.trackScreenView(`Search - ${Object.values(searchData).filter((val) => val!=='null').join('_')}`);
          }
        }
      } catch(error) {
        Utils.debug('Error in tracking', DEBUG.ERROR);
      }
    },
    async transformDrugForEdit(product: Drug): Promise<TransformedDrug> {
      const entityCategories = this.entCats.filter(
        (entCat) => entCat.entity_id === product.id
      );

      const activeingredients = entityCategories?.filter(
        (ec) => ec.category_type_code === "activeingredient"
      );

      const producer = this.producers.filter(
        (producer) => producer.id === product.producer_id
      )[0];

      return {
        id: product.id,
        title: product.title,
        dosage: product.details?.dosage_form?.text,
        strength: product.details?.strength?.text,
        activeingredient: activeingredients?.map((el: any) => el.category_title)?.join(", "),
        producer: producer ? producer.title : "",
        promoted: product.promoted,
        published: product.published,
      };
    },
    constructUrl(categories: Entity[], producer: Entity, query: string): void {
      const params = new URLSearchParams();

      if (categories.length) {
        categories.map((c: Entity) => {
          params.append('categoryId', c.id)
        })
      }

      if (Object.keys(producer).length) {
        params.append('producerId', producer.id)
      }

      if (query.length) {
        params.append('searchQuery', query)
      }

      const queryParams = params.toString()

      this.router.replace(`${this.$route.path}${ queryParams.length ? `?${queryParams}` : '' }`)
    },
    clearFilters() {
      this.search = {
        main: [],
      } as any;

      this.mainSearchInput = "";
    },
  },
  mounted() {
    const filterContainer = this.$refs.filterContainer as HTMLElement;

    if (filterContainer) {
      filterContainer.style.overflow = 'hidden';
    }
  },
});
</script>

<style lang="scss" scoped>
[disabled='true'] {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}
.container {
  .search {
    margin-bottom: 15px;
    padding: 0;
  }

  .latest-products {
    font-family: 'OpenSans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #595959;
    opacity: 1;
    margin: 20px 0 35px 0;
  }

  .button {
    height: 50px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.outline {
      transition: all 0.2s ease-in-out;
      border: 1px solid #eb3d47;
      color: #eb3d47;

      &:active {
        background-color: #eb3d47;
        color: white;
      }
    }
  }

  .filters {
    width: 100%;
    height: 0;

    &[open="true"] {
      animation: open 200ms ease-in-out 0s forwards;
    }

    &[open="false"] {
      animation: close 200ms ease-in-out 0s forwards;
    }

    @keyframes open {
      0% {
        height: 0;
      }
      99% {
        height: 180px;
      }
      100% {
        height: 100%;
      }
    }

    @keyframes close {
      0% {
        height: 100%;
      }
      1% {
        height: 180px;
      }
      100% {
        height: 0;
      }
    }

    .dropdown {
      width: 100%;
      margin-bottom: 15px;
    }

    .button.margin-b {
      margin-bottom: 15px;
    }
  }

  .search-loader {
    padding: 80px 0 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      width: 200px;
    }

    .header {
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
      margin-top: 25px;
      color: #eb3d47;
      text-align: center;
    }
  }

  .product-list-header {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin: 20px 0 35px 0;
    color: #595959;
  }

  .product-list {
    max-width: 812px;
    padding: 0 0 30px 0;
    margin: 0 auto;

    .product {
      width: 100%;
      padding: 0 0 20px;

      .drug-card {
        box-shadow: 0 0 45px #f8f8f8;
        border-radius: 10px;
        padding: 20px;
        cursor: pointer;

        img {
          display: block;
          margin: 37px auto 30px;
          max-width: 140px;
          max-height: 100px;
        }

        .title {
          margin: 0 0 5px 0;
          text-align: left;
          font-family: "OpenSans", sans-serif;
          font-size: 20px;
          font-weight: 600;
          color: #595959;
        }

        .dosage {
          margin: 0 0 5px 0;
          font-weight: 600;
          font-size: 16px;
          color: #595959;
          word-break: break-word;
        }

        .strength {
          margin: 0 0 5px 0;
          font-size: 16px;
          color: #595959;
          word-break: break-word;
        }

        .activeingredient {
          margin: 0 0 30px 0;
          font-size: 16px;
          color: #595959;
          word-break: break-word;
        }

        .producer {
          text-decoration: underline;
          font-size: 15px;
          font-family: "OpenSans", sans-serif;
          color: #848484;
        }
      }
    }
  }
}
</style>
