import { SQLiteConnection } from "@capacitor-community/sqlite";
import { SQLiteDBConnection } from "vue-sqlite-hook";
import { Footer, Menu } from "./wordpress";

/*
 * State
 */
export type State = {
  progress: number,
  navigation: {
    filters: boolean,
  },
  IS_DB_COPIED: boolean,
  IS_DB_OPENED: boolean,
  DATABASE: SQLiteDBConnection | null,
  SQLITE: SQLiteConnection | null,
  contacts: Footer,
  menu: Menu[],
  canDoPageReload: boolean,
};

/*
 * Mutations
 */
export const enum MUTATIONS {
  SET_TOGGLE_FILTERS =  'SET_TOGGLE_FILTERS',
  SET_PRELOAD_PROGRESS = 'SET_PRELOAD_PROGRESS',
  SET_TOGGLE_PAGE_RELOAD = 'SET_TOGGLE_PAGE_RELOAD',
}


/*
 * Actions
 */
export const enum ACTIONS {
  DATABASE_OPEN =  'DATABASE_OPEN',
  DATABASE_CLOSE =  'DATABASE_CLOSE',
  RUN_QUERY =  'RUN_QUERY',
  EXECUTE_QUERY =  'EXECUTE_QUERY',
  CACHE_MENU =  'CACHE_MENU',
  CACHE_FOOTER =  'CACHE_FOOTER',
}