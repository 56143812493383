import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1c8b9b6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }
const _hoisted_2 = ["onClick", "active"]
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "head" }
const _hoisted_5 = { class: "icon" }
const _hoisted_6 = {
  key: 0,
  class: "sub-title"
}
const _hoisted_7 = {
  key: 0,
  class: "formulation"
}
const _hoisted_8 = {
  key: 1,
  class: "strength"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drugs, (drug) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([
        'link',
        { 'atc-product': drug.producer_id },
        { 'no-children': !drug.branch_item_count && !drug.children_count },
        { 'no-products': !drug.producer_id && !drug.branch_item_count },
      ]),
        key: drug.id,
        onClick: ($event: any) => (_ctx.onClickHandler(drug)),
        active: _ctx.selected.filter((breadcrumb) => breadcrumb.id === drug.id).length > 0
      }, [
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(`title ${drug.producer_id ? 'is-a-product' : ''}`)
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(drug.producer_id ? '' : drug.code) + " " + _toDisplayString(drug.title), 1),
            _createElementVNode("span", _hoisted_5, [
              (!drug.producer_id)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    class: "link-icon",
                    icon: ['fal', 'angle-right']
                  }))
                : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    class: "link-icon",
                    icon: ['fas', 'external-link-alt']
                  }))
            ])
          ], 2),
          (drug.producer_id)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                (drug.dosage_form && drug.dosage_form !== 'null')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(drug.dosage_form), 1))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(" ") + " "),
                (drug.strength && drug.strength !== 'null')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(" + _toDisplayString(drug.strength) + ")", 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}