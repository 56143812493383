<template>
  <ion-page>
    <app-header />

    <main class="main">
      <ion-content ref="content" :scroll-events="true" scrollY @ionScroll="logScrolling($event)">
        <template v-if="canDoPageReload">
          <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
            <ion-refresher-content />
          </ion-refresher>
        </template>
        <div class="container">
          <slot></slot>
        </div>

        <app-footer />
      </ion-content>
    </main>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  loadingController,
} from "@ionic/vue";
import Navigation from "@/components/Navigation/Navigation.vue";
import Footer from "@/components/Navigation/Footer.vue";
import { defineComponent } from "vue";
import Migration from "@/utils/migration";
import { Utils } from "@/utils/utils";
import { DEBUG } from "@/models/utils";
import { store } from "@/store";

export default defineComponent({
  name: "App",
  props: {
    element_name: {
      type: String,
    },
  },
  components: {
    IonPage,
    IonContent,
    appHeader: Navigation,
    appFooter: Footer,
    IonRefresher,
    IonRefresherContent,
  },
  emits: ['reload:page', 'load:more'],
  data: () => ({
    scrollTop: 0,
    threshold: 0,
    loader: {} as HTMLIonLoadingElement,
  }),
  computed: {
    canDoPageReload(): boolean {
      return store.state.canDoPageReload;
    },
    progress(): number {
      return store.state.progress;
    },
  },
  watch: {
    progress(value: number) {
      if (this.loader) {
        this.loader.message = `Vent venligst... ${value}%`
      }
    },
  },
  methods: {
    scrollTo(): void {
      if (this.element_name) {
        const element = document.querySelector(`#${this.element_name}`);
        if (element) {
          const content = this.$refs.content as any;
          const rect = element.getBoundingClientRect();
          content.$el.scrollToPoint(0, this.scrollTop + (rect.top - 100), 1000);
        }
      }
    },
    scrollToTop(): void {
      const content = this.$refs.content as any;
      content.$el.scrollToTop(1000);
    },
    logScrolling(event: CustomEvent): void {
      this.scrollTop = event.detail.scrollTop as number;

      if (this.scrollTop >= this.threshold && this.threshold !== 0) {
        this.$emit('load:more');
        this.threshold = 0;
      }
    },
    setMinThreshold(threshold: number) {
      this.threshold = Math.round(threshold);
    },
    async doRefresh(event: CustomEvent) {
      (event.target as any).complete();
      this.loader = await loadingController.create({
        message: "Vent venligst... 0%",
      });

      await this.loader.present();
      await Migration.run();
      this.$emit('reload:page');
      Utils.debug(`${this.$route.name as string} page reloaded`, DEBUG.MESSAGE);
      this.loader.dismiss();
    },
  },
});
</script>
