import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slider-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-slider", { prev: _ctx.showPrev, next: _ctx.showNext }]),
    ref: "ui-slider"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "slider-items",
        style: _normalizeStyle(_ctx.transalteStyle)
      }, [
        _renderSlot(_ctx.$slots, "items")
      ], 4)
    ]),
    _withDirectives(_createVNode(_component_font_awesome_icon, {
      class: "prev",
      icon: ['fas', 'chevron-left'],
      onClick: _ctx.sliderPrev
    }, null, 8, ["onClick"]), [
      [_vShow, _ctx.showPrev]
    ]),
    _withDirectives(_createVNode(_component_font_awesome_icon, {
      class: "next",
      icon: ['fas', 'chevron-right'],
      onClick: _ctx.sliderNext
    }, null, 8, ["onClick"]), [
      [_vShow, _ctx.showNext]
    ])
  ], 2))
}