<template>
  <div
    class="ui-slider"
    :class="{ prev: showPrev, next: showNext }"
    ref="ui-slider"
  >
    <div class="slider-content">
      <div class="slider-items" :style="transalteStyle">
        <slot name="items"></slot>
      </div>
    </div>
    <font-awesome-icon
      class="prev"
      v-show="showPrev"
      :icon="['fas', 'chevron-left']"
      @click="sliderPrev"
    />
    <font-awesome-icon
      class="next"
      v-show="showNext"
      :icon="['fas', 'chevron-right']"
      @click="sliderNext"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiSlider',
  props: {
    id: {
      type: String,
      required: true,
    },
    scrollToEnd: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sliderWidth: 0,
    sliderItemsWidth: 0,
    translateAmount: 100,
    translate: 0,
  }),
  computed: {
    transalteStyle(): string {
      return `transform: translateX(-${this.translate}px)`;
    },
    showPrev(): boolean {
      return this.translate > 0;
    },
    showNext(): boolean {
      return this.sliderItemsWidth > this.sliderWidth + this.translate;
    },
  },
  watch: {
    sliderItemsWidth: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;

        if (this.scrollToEnd) {
          this.translate = this.sliderItemsWidth - this.sliderWidth;
        } else {
          this.translate = 0;
        }
      },
    },
  },
  methods: {
    sliderPrev() {
      if (this.translate < this.translateAmount) {
        this.translate = 0;
      } else {
        this.translate -= this.translateAmount;
      }
    },
    sliderNext() {
      const fromEnd = this.sliderItemsWidth - (this.sliderWidth + this.translate);

      if (fromEnd < this.translateAmount) {
        this.translate += fromEnd;
      } else {
        this.translate += this.translateAmount;
      }
    },
  },
  mounted() {
    const slider = this.$refs['ui-slider'] as HTMLElement;

    const event = new Event(`${this.id}-update`);

    const observer = new (window as any).MutationObserver(() => dispatchEvent(event));

    const sliderItems = slider?.getElementsByClassName('slider-items')[0];

    observer.observe(sliderItems, { subtree: true, childList: true });

    const mutations = observer.takeRecords();

    if (mutations) dispatchEvent(event);

    const events = ['load', 'resize', `${this.id}-update`];

    events.forEach((type: any) => window.addEventListener(type, () => {
      const isLessThanWindowWidth = (window.innerWidth - 100) <= 300

      slider.style.maxWidth = isLessThanWindowWidth ? `${window.innerWidth - 100}px` : '300px'

      this.sliderWidth = slider?.offsetWidth;
      this.sliderItemsWidth = sliderItems?.scrollWidth;
    }));

    this.$nextTick(() => dispatchEvent(event));
  },
});
</script>

<style lang="scss">
.ui-slider {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  position: relative;

  .slider-content {
    width: 100%;
    overflow: hidden;
    margin: 0.5px;

    .slider-items {
      display: inline-flex;
      width: 100%;
      transition: all 0.5s;
    }
  }

  .prev,
  .next {
    position: absolute;
    color: #ea4650;
    cursor: pointer;
    font-size: 14px;
    z-index: 10;
  }

  .prev {
    left: -40px;
  }

  .next {
    right: -40px;
  }
}
</style>
