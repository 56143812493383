import { Params, ApiResponse } from '@/models/api';
import httpClient from '@/utils/http.client';
import {
  Smpc, transformedSmpc,
} from '@/models/smpc';
import { store } from '@/store';
import { ACTIONS } from '@/models/store';
import QUERY from '@/utils/query';

class SmpcService {
  static async smpc(id: string): Promise<transformedSmpc> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.SMPC(id));

    const smpc = response.values[0];

    if (smpc) {
      smpc.smpc_sections = JSON.parse(smpc.smpc_sections);
      smpc.artifact = JSON.parse(smpc.artifact);
    }

    return smpc;
  }

  static async smpcs(): Promise<Smpc[]> {
    const response = await store.dispatch(ACTIONS.RUN_QUERY, QUERY.GET.SMPCS());

    const smpcs = response.values as any[];

    if (smpcs.length) {
      smpcs.map((smpc) => {
        smpc.smpc_sections = JSON.parse(smpc.smpc_sections);
        smpc.artifact = JSON.parse(smpc.artifact);
      })
    }

    return smpcs;
  }

  static async smpcData(params: Params): Promise<ApiResponse<Smpc> | null> {
    let response: ApiResponse<Smpc> | null;

    try {
      response = await httpClient.get('/api/mobile/smpc', { params });
    } catch (error) {
      response = null;
    }

    return response;
  }
}

export default SmpcService;
