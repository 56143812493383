<template>
  <div
    class="ui-combobox"
    :class="{ focus: focus, disabled: disabled, 'read-only': readOnly }"
  >
    <div class="ui-combobox-overlay" tabindex="0" @focus="setFocus"></div>
    <div class="ui-combobox-box">
      <input
        :value="searchInput"
        @input="input = $event.target.value;"
        ref="ui-combobox-input"
        :placeholder="computedPlaceholder"
        @focus="focus = true"
        @blur="focus = false"
        class="ui-combobox-input"
        :disabled="disabled || readOnly"
      />
      <template v-if="!disabled && !readOnly">
        <font-awesome-icon
          class="ui-combobox-icon remove"
          v-if="!multiple && !focus && model[itemValue]"
          :icon="['fal', 'times']"
          @click.stop="model = {}"
        />
        <font-awesome-icon
          class="ui-combobox-icon search"
          v-else
          :icon="['fal', 'search']"
        />
      </template>
    </div>
    <div v-if="focus" class="ui-combobox-dropdown">
      <div class="no-result" v-if="!items.length">Ingen resultat</div>
      <div v-else>
        <ui-scroll>
          <template v-for="(item, i) in items" :key="`item-${i}`">
            <div
              class="dropdown-item"
              :class="{ selected: isSelected(item[itemValue]) }"
              @mousedown="selectItem(item)"
            >
              <slot name="item">
                <span class="title" :title="item[itemText]">{{ item[itemText] }}</span>
              </slot>
            </div>
          </template>
        </ui-scroll>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UiScroll from '@/components/ui/form/UiScroll.vue';
import { store } from '@/store';
import { MUTATIONS } from "@/models/store";

export default defineComponent({
  name: 'UiCombobox',
  props: {
    modelValue: {
      type: Object || Array,
    },
    searchInput: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
    },
    itemText: {
      type: String,
      default: 'title',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    focus: false,
  }),
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
    input: {
      get(): string {
        return this.searchInput;
      },
      set(val: string) {
        this.$emit('update:search-input', val);
      },
    },
    computedPlaceholder(): string {
      return this.multiple ? this.placeholder : this.model[this.itemText] || this.placeholder;
    },
  },
  components: {
    UiScroll,
  },
  watch: {
    focus(val) {
      if (!val) this.input = '';
      store.commit(MUTATIONS.SET_TOGGLE_PAGE_RELOAD, !val)
    },
  },
  methods: {
    setFocus() {
      this.$nextTick(() => {
        (this.$refs['ui-combobox-input'] as HTMLElement).focus();
      });
    },
    selectItem(item: any) {
      if (!this.multiple) {
        if (this.model[this.itemValue] === item[this.itemValue]) {
          this.model = {};
          return;
        }

        this.model = item;
      }

      if (this.multiple) {
        const isSelected = this.model.some(
          (el: any) => el[this.itemValue] === item[this.itemValue],
        );

        if (isSelected) {
          this.model = this.model.filter(
            (el: any) => el[this.itemValue] !== item[this.itemValue],
          );
          return;
        }

        this.model.push(item);
      }

      this.input = '';
    },
    isSelected(id: string) {
      if (!this.multiple) {
        return this.model[this.itemValue] === id;
      }

      if (this.multiple) {
        return this.model.some((el: any) => el[this.itemValue] === id);
      }

      return false;
    },
  },
});
</script>

<style lang="scss" scoped>
.ui-combobox {
  display: flex;
  width: 100%;
  height: 50px;
  background: #f8f8f8;
  color: #595959;
  position: relative;
  border-radius: 10px;
  font-size: 16px;
  position: relative;

  &.focus {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      position: absolute;
      bottom: 0;
      z-index: -1;
      background-color: #edebeb;
    }
  }

  ::placeholder {
    font-weight: 400;
    font-family: "OpenSans", sans-serif;
    font-size: 14px;
    color: #595959;
  }

  &.focus,
  &:hover {
    background: #edebeb;
  }

  &.focus {
    ::placeholder {
      font-weight: 400;
      color: #9d9d9d;
    }
  }

  &.disabled:not(.read-only) {
    opacity: 0.3;
    user-select: none;
    pointer-events: none !important;
  }

  &.read-only {
    background: #fff;
  }

  .ui-combobox-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .ui-combobox-dropdown {
    border-radius: 0 0 10px 10px;
    background: #f8f8f8;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 10;

    .no-result {
      padding: 0 10px;
      height: 50px;
      font-weight: 400;
      font-size: 14px;
      line-height: 50px;
    }

    .ui-scroll {
      max-height: 250px;
    }

    .dropdown-item {
      display: flex;
      cursor: pointer;
      padding: 0 10px;
      height: 50px;
      line-height: 50px;

      &:hover,
      &.selected {
        background: #edebeb;
      }

      .title {
        max-width: 100%;
        margin-right: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .ui-combobox-box {
    width: 100%;

    .ui-combobox-input {
      position: relative;
      background-color: transparent;
      font-size: 14px;
      font-family: 'OpenSans', sans-serif;
      font-weight: 400;
      text-overflow: ellipsis;
      color: #595959;
      padding: 0 40px 0 10px;
      height: 50px;
      width: 100%;
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      box-sizing: border-box;
      cursor: default;

      &:focus {
        outline: none;
      }
    }

    .ui-combobox-icon {
      position: absolute;
      color: #595959;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 15px;

      &.remove {
        cursor: pointer;
      }

      &.search {
        pointer-events: none;
      }
    }
  }
}
</style>
