import { PathType } from '@/models/cache';
import { DEBUG } from '@/models/utils';
import CustomPlugin from '@/plugins/CustomPlugin';
import { Utils } from '@/utils/utils';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

class CacheService {
  static async write(filewebpath: string, filename: string, pathType: PathType, directory?: Directory): Promise<string | null> {
    let response;

    try {
      response = await Filesystem.writeFile({
        path: `${process.env.VUE_APP_ROOT_CACHE_FOLDER_NAME}/${pathType.concat(filename)}`,
        data: await Utils.base64FromPath(filewebpath),
        directory: directory ? directory : Directory.Cache,
        recursive: true,
        encoding: pathType === PathType.FILE ? undefined : Encoding.UTF8,
      });

      Utils.debug(`Writing File ${filename}`, DEBUG.ERROR);
    } catch (error) {
      Utils.debug(`Writing File ${filename}: ${error}`, DEBUG.ERROR);
      return null;
    }

    return response.uri
  }

  static async read(filename: string, pathType: PathType, directory?: Directory): Promise<string | null> {
    let response: any;

    try {
      response = await Filesystem.readFile({
        path: `${process.env.VUE_APP_ROOT_CACHE_FOLDER_NAME}/${pathType.concat(filename)}`,
        directory: directory ? directory : Directory.Cache,
        encoding: Encoding.UTF8,
      });

      Utils.debug(`Reading File ${filename}`, DEBUG.ERROR);
    } catch (error) {
      Utils.debug(`Reading File ${filename}: ${error}`, DEBUG.ERROR);
      return null;
    }

    return response.data
  }

  static async getUri(filename: string, pathType: PathType, directory?: Directory): Promise<string | null> {
    let response;

    try {
      response = await Filesystem.getUri({
        path: `${process.env.VUE_APP_ROOT_CACHE_FOLDER_NAME}/${pathType.concat(filename)}`,
        directory: directory ? directory : Directory.Cache,
      });
    } catch (error) {
      Utils.debug(`Getting File ${filename} URI: ${error}`, DEBUG.ERROR);
      return null;
    }

    return response.uri
  }

  static async getDatabaseFiles(): Promise<string[] | null> {
    let response: any;

    try {
      response = await Filesystem.readdir({
        path: 'CapacitorDatabase/',
        directory: Directory.Library
      });

    } catch (error) {
      Utils.debug('Returing all database files', DEBUG.ERROR);
      return null;
    }

    return response.files;
  }

  static async removeOldDBFiles(): Promise<void> {
    const platform = Capacitor.getPlatform();
    const activeDBName = process.env.VUE_APP_DATABASE_NAME + "SQLite.db";

    if (platform === 'ios') {
      try {
        const files = await this.getDatabaseFiles();

        if (files) {
          if (files.length) {
            await files.reduce(async (promise: any, filename: string) => {
              await promise;

              if (filename.startsWith("medicintildyr-db") && filename !== activeDBName) {
                await this.delete('CapacitorDatabase/', filename, Directory.Library);
              } else {
                Utils.debug(`Skip Deleting File: ${filename}`, DEBUG.MESSAGE);
              }

            }, Promise.resolve());
          }
        }

      } catch (error) {
        Utils.debug('Returing all database files', DEBUG.ERROR);
      }

    } else {
      if (platform === 'android') {
        await CustomPlugin.deleteOldDBFilesAndroid({
          activeDBName,
        });
      }
    }
  }

  static async delete(filepath: string, filename: string, directory?: Directory): Promise<void> {
    try {
      await Filesystem.deleteFile({
        path: `${filepath}/${filename}`,
        directory: directory ? directory : Directory.Cache,
      });
      Utils.debug(`Success Deleting File: ${filename}`, DEBUG.INFO);
    } catch (error) {
      Utils.debug(`Error Deleting File: ${filename} having Error: ${error}`, DEBUG.ERROR);
    }
  }
}

export default CacheService;
