import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header),
      _createElementVNode("main", _hoisted_1, [
        _createVNode(_component_ion_content, {
          ref: "content",
          "scroll-events": true,
          scrollY: "",
          onIonScroll: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logScrolling($event)))
        }, {
          default: _withCtx(() => [
            (_ctx.canDoPageReload)
              ? (_openBlock(), _createBlock(_component_ion_refresher, {
                  key: 0,
                  slot: "fixed",
                  onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doRefresh($event)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_refresher_content)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _createVNode(_component_app_footer)
          ]),
          _: 3
        }, 512)
      ])
    ]),
    _: 3
  }))
}