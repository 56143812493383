<template>
  <div class="search" :class="{ focus: focus, 'no-items': !items.length }">
    <div class="search-filters" v-if="model && model.length">
      <ui-slider id="filters-slider" class="filters-slider" :scrollToEnd="true">
        <template #items>
          <template v-for="(item, i) in model" :key="`tag-${i}`">
            <div class="tag">
              <span class="tag-title">{{ item.title }}</span>
              <font-awesome-icon
                :icon="['fal', 'times']"
                @click="removeItem(item.id)"
                class="remove-icon"
              />
            </div>
          </template>
        </template>
      </ui-slider>
    </div>
    <div class="search-box">
      <input
        :value="searchInput"
        @input="input = $event.target.value;"
        :placeholder="placeholder"
        @focus="focus = true"
        @blur="focus = false"
        class="search-input"
      />
      <font-awesome-icon class="search-icon" :icon="['fal', 'search']" />
    </div>
    <div v-if="focus" class="search-dropdown">
      <ui-scroll>
        <template v-for="(item, i) in items" :key="`item-${i}`">
          <div
            class="dropdown-item"
            :class="{ selected: isSelected(item.id) }"
            @mousedown="addItem(item)"
            :title="item.title"
          >
            <span class="title">{{ item.title }}</span>
            <span class="type" v-if="item.category_type_title">
              {{ item.category_type_title }}
            </span>
          </div>
        </template>
      </ui-scroll>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UiScroll from '@/components/ui/form/UiScroll.vue';
import UiSlider from '@/components/ui/form/UiSlider.vue';
import { store } from '@/store';
import { MUTATIONS } from "@/models/store";

export default defineComponent({
  name: 'Search',
  props: {
    modelValue: {
      type: Array,
    },
    searchInput: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
  },
  data: () => ({
    focus: false,
  }),
  watch: {
    focus(val: boolean) {
      store.commit(MUTATIONS.SET_TOGGLE_PAGE_RELOAD, !val)
    },
  },
  computed: {
    model: {
      get(): any {
        let DO_SORT = false

        if (Array.isArray(this.modelValue)) {
          const item = (this.modelValue as any[])[0];

          if (item) {
            DO_SORT = Object.keys(item).includes('SEARCH_ORDER')
          }
        }

        if (DO_SORT) {
          const sorted = (this.modelValue as any[]).sort((a: any, b: any) => a.SEARCH_ORDER - b.SEARCH_ORDER)
          return sorted;
        } else {
          return this.modelValue;
        }
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
    input: {
      get(): string {
        return this.searchInput;
      },
      set(val: string) {
        this.$emit('update:search-input', val);
      },
    },
  },
  components: {
    UiScroll,
    UiSlider,
  },
  methods: {
    addItem(item: any) {
      let temp = this.model as any[];

      if (item.category_type_title === "FIRMA") {
        temp = temp.filter(
          (cat: any) => cat.category_type_title !== "FIRMA"
        );
      }
      const isSelected = temp.some((el: any) => el.id === item.id);

      if (isSelected) {
        temp = temp.filter((el: any) => el.id !== item.id);
        return;
      }

      temp.push(item);

      this.model = [...temp];

      this.input = '';
    },
    removeItem(itemId: string) {
      this.model = this.model.filter((el: any) => el.id !== itemId);
    },
    isSelected(itemId: string) {
      return this.model.some((el: any) => el.id === itemId);
    },
  },
});
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  width: 100%;
  height: 50px;
  background: #f8f8f8;
  color: #595959;
  position: relative;
  border-radius: 10px;
  font-size: 16px;
  z-index: 5;

  &.focus:not(.no-items) {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      position: absolute;
      bottom: 0;
      z-index: -1;
      background-color: #edebeb;
    }
  }

  &.focus,
  &:hover {
    background: #edebeb;
  }

  .search-filters {
    max-width: 66.67%;
    width: auto;
    padding: 0 0 0 15px;
    display: flex;
    align-items: center;

    .tag {
      position: relative;
      height: 30px;
      display: flex;
      align-items: center;
      margin-right: 4px;
      padding: 0 12px;
      background: #ea4650;
      color: #fff;
      border-radius: 15px;
      cursor: default;

      .tag-title {
        margin-right: 17px;
        white-space: nowrap;
        user-select: none;
      }

      .remove-icon {
        cursor: pointer;
        font-size: 15px;
      }
    }
  }

  .search-dropdown {
    border-radius: 0 0 10px 10px;
    background: #f8f8f8;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;

    .ui-scroll {
      max-height: 250px;
    }

    .dropdown-item {
      display: flex;
      cursor: pointer;
      padding: 0 15px;
      height: 50px;
      line-height: 50px;

      &:hover,
      &.selected {
        background: #edebeb;
      }

      .title {
        max-width: 70%;
        margin-right: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .type {
        font-size: 14px;
      }
    }
  }

  .search-box {
    width: 100%;

    .search-input {
      position: relative;
      background-color: transparent;
      font-size: 14px;
      font-family: 'OpenSans', sans-serif;
      color: #595959;
      text-overflow: ellipsis;
      padding: 0 46px 0 15px;
      height: 50px;
      width: 100%;
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      box-sizing: border-box;
      cursor: default;

      &:focus {
        outline: none;
      }
    }

    .search-icon {
      position: absolute;
      color: #595959;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 15px;
      font-size: 15px;
      pointer-events: none;
    }
  }
}

.filters-slider {
  max-width: 100% !important;
  box-sizing: border-box;

  &.prev::before,
  &.next::after {
    content: '';
    display: block;
    width: 44px;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
  }

  &.prev::before {
    left: 0;
    background: linear-gradient(to left, transparent, #f8f8f8, #f8f8f8);
  }

  &.next::after {
    right: 0;
    background: linear-gradient(to right, transparent, #f8f8f8, #f8f8f8);
  }
}

.search.focus,
.search:hover {
  .filters-slider.prev::before {
    background: linear-gradient(to left, transparent, #edebeb, #edebeb);
  }

  .filters-slider.next::after {
    background: linear-gradient(to right, transparent, #edebeb, #edebeb);
  }
}
</style>

<style lang="scss">
.filters-slider {
  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }
}
</style>
