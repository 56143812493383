<template>
  <div class="menu">
    <div
      :class="[
        'link',
        { 'atc-product': drug.producer_id },
        { 'no-children': !drug.branch_item_count && !drug.children_count },
        { 'no-products': !drug.producer_id && !drug.branch_item_count },
      ]"
      v-for="drug in drugs"
      :key="drug.id"
      @click="onClickHandler(drug)"
      :active="selected.filter((breadcrumb) => breadcrumb.id === drug.id).length > 0"
    >
      <span class="content">
        <div :class="`title ${drug.producer_id ? 'is-a-product' : ''}`">
          <span class="head">{{ drug.producer_id ? '' : drug.code }} {{ drug.title }}</span>

          <span class="icon">
            <template v-if="!drug.producer_id">
              <font-awesome-icon class="link-icon" :icon="['fal', 'angle-right']" />
            </template>
            <template v-else>
              <font-awesome-icon class="link-icon" :icon="['fas', 'external-link-alt']" />
            </template>
          </span>
        </div>

        <template v-if="drug.producer_id">
          <span class="sub-title">
            <template v-if="drug.dosage_form && drug.dosage_form !== 'null'">
              <span class="formulation">{{drug.dosage_form}}</span>
            </template>
            {{ " " }}
            <template v-if="drug.strength && drug.strength !== 'null'">
              <span class="strength">({{drug.strength}})</span>
            </template>
          </span>
        </template>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Drug } from '@/models/medicine';
import { Breadcrumb } from '@/models/category';

export default defineComponent({
  name: 'ATCSidebar',
  props: {
    drugs: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
    },
  },
  emits: ['drug-selected', 'drug-route'],
  methods: {
    onClickHandler(stack: Drug): void {
      if (stack.producer_id) {
        this.$emit('drug-route', stack.id);
        return;
      }

      if (!stack.item_count && !stack.children_count) return;

      this.$emit('drug-selected', stack);
    },
    isSelected(id: string): boolean {
      const breadcrumbs = this.selected as Breadcrumb[];
      return breadcrumbs.filter((breadcrumb) => breadcrumb.id === id).length > 0;
    },
  },
});
</script>

<style lang="scss" scoped>
.menu {
  width: 500px;

  .link {
    color: #595959;
    width: 100%;
    cursor: pointer;
    padding: 15px 10px 15px;
    border-bottom: 1px solid #93939329;
    box-sizing: border-box;

    .content {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 16px;
        line-height: 22px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        word-break: break-all;

        .icon {
          margin-left: 10px;
        }
      }

      .sub-title {
        font-size: 12px;
        line-height: 22px;
        color: #9e9e9e;
      }
    }

    .is-a-product {
      color: #EB3D47;
    }

    &[active='true'] {
      background-color: #595959;
      color: #ffffff;
    }

    &.atc-product {
      .title {
        font-weight: bold;
      }
    }

    &.no-products {
      font-style: italic;
    }

    &:not(.atc-product).no-children {
      cursor: default;
      font-style: italic;

      .link-icon {
        display: none;
      }
    }
  }
}
</style>
